import React from 'react';
import classNames from 'classnames';
import { ReactComponent as CheckmarkSvg } from '../../img/checkmark.svg';

import styles from '../../styles/input/option-input.module.scss';

const OptionInput = ({ type = 'radio', name, value, label, checked, defaultChecked, onChange, style = {} }) => (
    <div className={classNames(styles.root, `is-${type}`)} style={style}>
        <label className={styles.container}>
            <input type={type} name={name} checked={checked} defaultChecked={defaultChecked}
                   value={value} onChange={onChange} className="u-sr-only"/>
            <span className={styles.box}>
                {(() => {
                    switch (type) {
                        case 'checkbox':
                            return (<CheckmarkSvg style={{ opacity: checked || defaultChecked ? 1 : 0 }} stroke="#333f4d" strokeWidth={2}/>);
                        default:
                            return (<div className={styles.dot}
                                         style={{ opacity: checked || defaultChecked ? 1 : 0 }}/>);
                    }
                })()}
            </span>
            <span className={styles.label}>{label}</span>
        </label>
    </div>
);

export default OptionInput;
