import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import BasicDialog from "./dialogs/BasicDialog";

import { ReactComponent as InfoSvg } from '../img/icon-info.svg';
import i18n from "../i18n";

const RED_COLOR = '#D75946';

const FileInfoModalToggle = ({ file, width = 14, height = 14, style = {}, history }) => {

    const [dialog, setDialog] = useState(null);

    const { title, categories, keywords, altText, description: caption, place } = file;

    const hasCategories = !!((categories || []).length);
    const hasCaption = !!((caption || '').replace(/\s/g,'').length);
    const hasAltText = !!((altText || '').replace(/\s/g,'').length);
    const hasKeywords = !!((keywords || []).length);
    const hasPlace = !!((place || []).length);

    const hasLowRating = !hasCategories || !hasCaption || !hasAltText;

    const iconColor = hasLowRating ? RED_COLOR : 'currentColor';

    return (
        <>
            {dialog ? (<BasicDialog {...dialog}/>) : null}
            <button style={style} onClick={() => {
                setDialog({
                    title,
                    text: (
                        <div>
                            <div style={{ paddingTop: 10 }}>
                                <p style={{ marginBottom: 10 }}><strong>Caption: </strong><br/>
                                    {hasCaption ? caption : (<span style={{ color: RED_COLOR }}><strong>None</strong></span>)}
                                </p>
                                <p style={{ marginBottom: 10 }}><strong>Alternative text: </strong><br/>
                                    {hasAltText ? altText : (<span style={{ color: RED_COLOR }}><strong>None</strong></span>)}
                                </p>
                                <p style={{ marginBottom: 10 }}><strong>Categories: </strong><br/>
                                    {hasCategories ? categories.map(category => category.title).join(', ') : (<span style={{ color: RED_COLOR }}><strong>None</strong></span>)}
                                </p>
                                {hasKeywords ? (
                                    <p style={{ marginBottom: 10 }}><strong>Keywords: </strong><br/>{keywords.map(keywords => keywords.title).join(', ')}</p>
                                ) : null}
                                {hasPlace ? (
                                    <p style={{ marginBottom: 10 }}><strong>Places: </strong><br/><p>{place.map(place => place.title).join(', ')}</p></p>
                                ) : null}
                            </div>
                        </div>
                    ),
                    cancelLabel: i18n('Close'),
                    onCancel: () => setDialog(null),
                    confirmLabel: i18n('Edit Image'),
                    onConfirm: () => {
                        setDialog(null);
                        history.push(`/edit/${file.id}`);
                    }
                });
            }}>
                <InfoSvg width={width} height={height} fill={iconColor} style={{
                    width,
                    height
                }} />
            </button>
        </>
    );
};

export default withRouter(FileInfoModalToggle);
