import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Button from "./Button";

import { ReactComponent as ArrowSvg } from '../img/arrow-down.svg';

import styles from '../styles/popout-menu.module.scss';

const PopoutMenu = ({ label, position, defaultAction, children, styles: customStyles = {}, renderTrigger, TriggerComponent = Button, onOpen, arrowPosition, ...props }) => {

    const [open, setOpen] = useState(false);

    const onBodyClick = () => {
        if (open) {
            setOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', onBodyClick);
        return () => {
            window.removeEventListener('click', onBodyClick);
        };
    }, [open]);

    useEffect(() => {
        if (open && onOpen) {
            onOpen();
        }
    }, [open]);

    let menuStyle = {};

    if (position === 'top right') {
        menuStyle = {
            right: '0',
            bottom: '100%'
        };
    } else if (position === 'center right') {
        menuStyle = {
            right: '100%',
            top: '50%',
            transform: 'translate(0, -50%)'
        };
    } else if (position === 'bottom right') {
        menuStyle = {
            right: '100%',
            bottom: '0'
        };
    } else if (position === 'top center') {
        menuStyle = {
            left: '50%',
            bottom: '100%',
            transform: 'translate(-50%, 0)'
        };
    } else {
        // Default – "top left"
        menuStyle = {
            left: 0,
            bottom: '100%'
        };
    }

    menuStyle = {
        ...menuStyle,
        ...(customStyles.menu || {})
    };

    const triggerProps = {
        'aria-expanded': open,
        onClick: () => setOpen(!open),
        ...props
    };

    return (
        <>
            {renderTrigger ? renderTrigger(triggerProps) : (
                <TriggerComponent {...triggerProps}>
                    {label ? label : (
                        <ArrowSvg style={{
                            width: 14,
                            height: 10,
                            transform: 'scale(1, -1)',
                            stroke: 'currentColor'
                        }}/>
                    )}
                </TriggerComponent>
            )}
            <div className={classNames(styles.menu, arrowPosition ? `arrow${arrowPosition}` : null)} style={menuStyle} hidden={!open}>
                <div className={styles.options}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default PopoutMenu;
