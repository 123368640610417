import React from 'react';

import Select  from 'react-select';

import styles from '../../styles/input/dropdown-input.module.scss';

const DropdownInput = ({ label, name, value, options = [], style = {}, multiple: isMulti = false, clearable: isClearable = true, maxMenuHeight = 300, placeholder, onChange, disabled = false }) => {
    return (
        <div className={styles.root} style={{
            ...style,
            pointerEvents: disabled ? 'none' : '',
            opacity: disabled ? 0.5 : ''
        }}>
            {label ? (
                <label id={name ? `input-${name}` : null}>{label}</label>
            ) : null}
            <Select
                options={options}
                isMulti={isMulti}
                isClearable={isClearable}
                value={value}
                isSearchable={false}
                onChange={onChange}
                placeholder={placeholder}
                maxMenuHeight={maxMenuHeight}
                classNamePrefix="select-dropdown"
                components={{
                    IndicatorSeparator: () => null
                }}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        fontSize: 14,
                        lineHeight: 1.2
                    }),
                    group: (provided) => ({
                        ...provided,
                        borderTop: '1px solid #E6E5E6',
                        ':first-child': {
                            paddingTop: 0,
                            borderTop: 'none'
                        }
                    }),
                    menu: (provided) => ({
                        ...provided,
                        margin: 0,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        fontSize: 14,
                        lineHeight: 1.2,
                        color: 'currentColor',
                        backgroundColor: state.isFocused && !state.isSelected ? '#C8CACD' : 'transparent',
                        cursor: state.isSelected ? 'default' : 'pointer',
                        padding: '8px 34px 8px 20px',
                        fontWeight: state.isSelected ? 'bold' : '',
                        transition: 'background-color 0.3s'
                    })
                }}
            />
        </div>
    );
};

export default DropdownInput;
