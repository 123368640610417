import React from 'react';
import ReactTooltip from 'react-tooltip';

const ToolTip = props => (
    <ReactTooltip
        className="tooltip"
        {...props}
    />
);

export default ToolTip;
