import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getUrl as getBunnyUrl } from '../bunny';
import {getImageForVideo as getMuxUrl} from '../mux';

import get from 'lodash/get';
import Button from './Button';
import ButtonGroup from "./ButtonGroup";
import LazyImage from './LazyImage';

import BasicDialog from './dialogs/BasicDialog';

import PopoutMenu from "./PopoutMenu";

import i18n from "../i18n";
import * as api from '../api';

import { postParentMessage, CONTEXT_FIELD } from '../helpers';

import {
    fileBrowserClearSelectedIds,
    fileBrowserRemoveFilesById,
    fileBrowserUpdateFiles,
    setIsAjaxLoading
} from "../store";

import styles from "../styles/filebrowser-actions.module.scss";

import { ReactComponent as RemoveSvg } from '../img/remove.svg';

const replaceFile = ({ file, assetId, setDialog, dispatch }) => {
    dispatch(setIsAjaxLoading(true));
    api
        .uploadAndReplaceFile({ file, assetId })
        .then(res => {
            const { status, data } = res;
            if (status !== 200 || data.error) {
                setDialog({
                    title: 'An error occurred',
                    text: data.error || i18n('An unknown error occurred'),
                    onConfirm: () => {
                        setDialog(null);
                    }
                });
                dispatch(setIsAjaxLoading(false));
            } else {
                // Get fresh asset data
                api
                    .getFile({ assetId })
                    .then(res => {
                        const { status, data } = res;
                        if (status === 200 && data && data.uid) {
                            dispatch(fileBrowserUpdateFiles({ files: [data]}));
                        };
                    })
                    .catch(error => {
                        this.setState({ data: false });
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch(setIsAjaxLoading(false));
                    });
            }
        })
        .catch(error => {
            setDialog({
                title: 'An error occurred',
                text: get(error, 'response.data.error', error),
                onConfirm: () => {
                    setDialog(null);
                }
            });
            dispatch(setIsAjaxLoading(false));
            console.error(error);
        });
};

const deleteFiles = ({ selectedFiles, setDialog, dispatch }) => {
    setDialog({
        title: selectedFiles.length > 1 ? i18n('Delete {count} files?', { count: selectedFiles.length }) : i18n('Delete {filename}?', { filename: (selectedFiles[0].damFilename ? `${selectedFiles[0].damFilename}.${selectedFiles[0].extension}` : null) || selectedFiles[0].originalFilename || selectedFiles[0].filename }),
        text: i18n('You will not be able to undo this action.'),
        onCancel: () => setDialog(null),
        onConfirm: () => {
            setDialog(null);
            dispatch(setIsAjaxLoading(true));
            Promise
                .all(api.deleteFiles(selectedFiles.map(file => file.id)))
                .then(responses => {
                    const deletedAssetIds = responses.map(res => parseInt(get(res, 'data.assetId'), 10)).filter(assetId => !!assetId);
                    const numSuccesses = deletedAssetIds.length;
                    const numTotalFiles = selectedFiles.length;
                    const onConfirm = () => {
                        setDialog(null);
                        dispatch(fileBrowserRemoveFilesById(deletedAssetIds));
                    };
                    if (numSuccesses === numTotalFiles) {
                        // All OK – all files deleted
                        setDialog(null);
                        onConfirm();
                    } else {
                        // Something went wrong
                        if (numTotalFiles === 1) {
                            setDialog({
                                title: i18n('An error occurred'),
                                text: i18n('Failed to delete {filename}', { filename: (selectedFiles[0].damFilename ? `${selectedFiles[0].damFilename}.${selectedFiles[0].extension}` : null) || selectedFiles[0].originalFilename || selectedFiles[0].filename }),
                                onConfirm
                            });
                        } else {
                            setDialog({
                                title: i18n('Errors occurred'),
                                text: i18n('Failed to delete {count} files.', { count: numTotalFiles - numSuccesses }),
                                onConfirm
                            });
                        }
                    }
                })
                .catch(error => {
                    const { error: message } = (error.response || {}).data || {};
                    setDialog({
                        title: i18n('An error occurred'),
                        text: message || i18n('An unknown error occurred.'),
                        onConfirm: () => setDialog(null)
                    });
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setIsAjaxLoading(false));
                });
        }
    });
};

const downloadFiles = ({ selectedFiles, setDialog, dispatch }) => {
    dispatch(setIsAjaxLoading(true));
    api
        .downloadFiles({ assetIds: selectedFiles.map(file => file.id) })
        .then(res => {
            const { status, data, headers } = res;
            if (status !== 200 || !data) {
                setDialog({
                    title: i18n('An error occurred'),
                    text: i18n('Could not download {count} files. Please try again later.', { count: selectedFiles.length }),
                    onConfirm: () => {
                        setDialog(null);
                    }
                });
                return;
            }
            // Get filename
            const contentDisposition = get(headers, 'content-disposition');
            let filename = 'download.zip';
            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            // Trigger the download
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename || 'download.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(error => {
            const errorMessage = get(error, 'response.data.error');
            console.error(error);
            setDialog({
                title: i18n('An error occurred'),
                text: errorMessage || i18n('An unknown error occurred'),
                onConfirm: () => {
                    setDialog(null);
                }
            });
        })
        .finally(() => {
            dispatch(setIsAjaxLoading(false));
        });
};

export const ActionButtons = ({ selectedFiles, setDialog, dispatch, displayDangerousActions = true }) => (
    <>
        {/* Delete */}
        {displayDangerousActions ? (
            <>
                <button onClick={() => {
                    deleteFiles({ selectedFiles, setDialog, dispatch });
                }}>{selectedFiles.length > 1 ? i18n('Delete Files') : i18n('Delete File')}</button>
                {/* Replace */}
                {selectedFiles.length === 1 ? (
                    <>
                        <label>
                            <span>{i18n('Replace File')}</span>
                            <input type="file" accept=".jpg,.jpeg,.gif,.png" style={{ display: 'none' }} onChange={e => {
                                const file = e.target.files[0] || null;
                                if (!file) {
                                    return;
                                }
                                replaceFile({ file, assetId: selectedFiles[0].id, setDialog, dispatch });
                            }}/>
                        </label>
                    </>
                ) : null}
                <hr/>
            </>
        ) : null}
        {/* View Original */}
        {selectedFiles.length === 1 ? (
            <a href={selectedFiles[0].assetUrl} target="_blank"
               rel="noopener noreferrer">{i18n('View Original')}</a>
        ) : null}
        {/* Download */}
        <button onClick={() => {
            downloadFiles({ selectedFiles, dispatch, setDialog });
        }}>{selectedFiles.length > 1 ? i18n('Download Files') : i18n('Download File')}</button>
        {/* Share */}
        <button disabled={true} style={{ opacity: 0.5, cursor: 'default' }}>{selectedFiles.length > 1 ? i18n('Share Files') : i18n('Share File')}</button>
        {/* Add to board */}
        <button disabled={true} style={{ opacity: 0.5, cursor: 'default' }}>{i18n('Add to Board')}</button>
        {/* Bulk edit */}
        {selectedFiles.length > 1 ? (
            <Link
                to={`/bulk-edit/${selectedFiles.map(file => file.id).join(',')}`}>{i18n('Bulk Edit Files')}</Link>
        ) : null}
    </>
);

const FileBrowserActions = ({ context, selectedFiles, toggleSelect, history, dispatch }) => {

    const [dialog, setDialog] = useState(null);

    if (!selectedFiles || !selectedFiles.length) {
        return null;
    }

    let actionButtonLabel;
    if (selectedFiles.length > 1) {
        actionButtonLabel = i18n('Edit {count} Files', { count: selectedFiles.length });
    } else {
        actionButtonLabel = i18n('Edit File');
    }

    return (
        <>
            {dialog ? (
                <BasicDialog {...dialog}/>
            ) : null}
            <div className={styles.root}>
                {/* Action button */}
                <div className={styles.actions}>
                    {context === CONTEXT_FIELD ? (
                        <Button
                            onClick={() => {
                                postParentMessage({
                                    action: 'select-files',
                                    fileIds: selectedFiles.map(file => parseInt(file.id, 10))
                                });
                            }}
                            submit={true}
                        >{selectedFiles.length === 1 ? i18n('Select File') : i18n('Select {count} Files', { count: selectedFiles.length })}</Button>
                    ) : (
                        <ButtonGroup submit={true} style={{ position: 'relative' }}>
                            <Button onClick={() => {
                                history.push(`/edit/${selectedFiles.map(file => file.id)}`);
                            }}>{actionButtonLabel}</Button>
                            <PopoutMenu>
                                <ActionButtons selectedFiles={selectedFiles} setDialog={setDialog} dispatch={dispatch}/>
                            </PopoutMenu>
                        </ButtonGroup>
                    )}
                </div>
                {/* Selected files */}
                <div className={styles.files}>
                    <div>
                        <div>
                            <div className={styles.thumbs}>
                                <ol>
                                    {selectedFiles.map(file => (
                                        <li key={file.uid} className={styles.file}>
                                            <button onClick={() => toggleSelect(file.id)}>
                                                <RemoveSvg/>
                                                <div>
                                                    {file.kind === 'image' ? (
                                                        <LazyImage src={getBunnyUrl(file.path, {
                                                            width: 100,
                                                            aspect_ratio: '1:1'
                                                        })} width={50} height={50}/>
                                                    ) : (
                                                        <LazyImage src={getMuxUrl(file, {
                                                            width: 100,
                                                            height: 100,
                                                            fit: 'crop'
                                                        })} width={50} height={50}/>
                                                    )}
                                                </div>
                                            </button>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                            <div className={styles.fileactions}>
                                <button onClick={() => dispatch(fileBrowserClearSelectedIds())}>
                                    <span>{i18n('Clear all')}</span>
                                    <RemoveSvg/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withRouter(connect(state => ({
    context: state.context
}))(FileBrowserActions));
