import React, { useEffect } from 'react';
import '@mux-elements/mux-video';
import {MUX_KEY} from '../mux';

const MuxVideoPlayer = React.forwardRef(({ playbackId, muted = false, ...props}, ref) => {

    useEffect(() => {
        if (!ref || !ref.current) {
            return;
        }
        ref.current.muted = muted;
    }, [muted, ref]);

    return (
        <mux-video
            env-key={MUX_KEY}
            playback-id={playbackId}
            stream-type="on-demand"
            preload="metadata"
            playsInline
            ref={ref}
            {...props}
        />
    );
});

export default MuxVideoPlayer;
