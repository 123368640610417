import React, { useRef, useState, useEffect } from 'react';

import TextAreaAutosize from 'react-textarea-autosize';
import find from 'lodash/find';
import styles from '../../styles/input/multilangtext-input.module.scss';

import { ReactComponent as GlobeSvg } from "../../img/globe.svg";

import { ReactComponent as enFlagSvg } from "../../img/flag-icons/en.svg";
import { ReactComponent as svFlagSvg } from "../../img/flag-icons/sv.svg";
import { ReactComponent as nbFlagSvg } from "../../img/flag-icons/nb.svg";
import { ReactComponent as dkFlagSvg } from "../../img/flag-icons/dk.svg";
import { ReactComponent as deFlagSvg } from "../../img/flag-icons/de.svg";
import PopoutMenu from "../PopoutMenu";
import i18n from "../../i18n";

import * as api from '../../api';
import Loader from "../Loader";

const FLAGS = {
    en: enFlagSvg,
    sv: svFlagSvg,
    nb: nbFlagSvg,
    dk: dkFlagSvg,
    de: deFlagSvg
};

const MultiLangTextInput = ({ sites, name, label, postfix, value, type = 'text', style = {}, onChange, placeholder, multiline, readOnly = false, autoComplete = 'off', autoCorrect = 'off', autoCapitalize = 'off', spellCheck = false, translateable, translationMenuPosition = 'center right', instructions, disabled, onKeyUp }) => {

    const inputRef = useRef(null);
    const postfixRef = useRef(null);

    const [postfixWidth, setPostfixWidth] = useState(0);
    const [pendingTranslations, setPendingTranslations] = useState([]);

    useEffect(() => {
        const { current: postfixEl } = postfixRef;
        const { current: input } = inputRef;
        if (!postfixEl || !input) {
            return;
        }
        const { width } = postfixEl.getBoundingClientRect();
        setPostfixWidth(Math.ceil(width));
    }, [postfix]);

    const isTranslatable = translateable !== false;

    value = value || {};

    return (
        <fieldset className={styles.root} style={style}>
            {label ? (
                <div className={styles.header}>
                    <legend>{label}</legend>
                </div>
            ) : null}
            <div className={styles.inputs}>
                {sites.map(site => {

                    const siteValue = value[site.language] || '';

                    const inputProps = {
                        name: name ? `${name}-${site.language}` : null,
                        ref: inputRef,
                        className: styles.input,
                        type: type,
                        value: siteValue,
                        placeholder: placeholder,
                        readOnly: readOnly,
                        autoComplete: autoComplete,
                        autoCapitalize: autoCapitalize,
                        spellCheck: spellCheck,
                        autoCorrect: autoCorrect,
                        disabled,
                        style: {
                            paddingRight: postfixWidth ? postfixWidth + 12 : ''
                        },
                        onKeyUp: e => {
                            e.stopPropagation();
                            if (onKeyUp) {
                                onKeyUp(e);
                            }
                        },
                        onChange: e => {
                            if (!onChange) {
                                return;
                            }
                            onChange({
                                ...value,
                                [site.language]: e.target.value
                            });
                        }
                    };

                    const possibleTranslations = Object.keys(value || {}).filter(language => language !== site.language && !!(value || {})[language]);
                    const canBeTranslated = !(siteValue.replace(/\s/g,'')).length && possibleTranslations.length;

                    return (
                        <div key={site.uid} className={styles.inputwrap}>
                            <div className={styles.input}>
                                <div className={styles.flag}>
                                    {(() => {
                                        const FlagComponent = FLAGS[site.language] || null;
                                        if (!FlagComponent) {
                                            return null;
                                        }
                                        return (<FlagComponent/>);
                                    })()}
                                </div>
                                {multiline ? (
                                    <TextAreaAutosize
                                        {...inputProps}
                                    />
                                ) : (
                                    <input
                                        {...inputProps}
                                    />
                                )}
                                {postfix || isTranslatable ? (
                                    <div ref={postfixRef} className={styles.postfix}>
                                        {postfix ? (<span>{postfix}</span>) : null}
                                        {isTranslatable ? (
                                            <>
                                                <PopoutMenu
                                                    position={translationMenuPosition}
                                                    styles={{
                                                        menu: {
                                                            width: 'auto',
                                                            minWidth: 0,
                                                            borderRadius: 3,
                                                            boxShadow: '0px 2px 5px 0px rgba(51, 63, 77, 0.2)',
                                                            marginRight: 4
                                                        }
                                                    }}
                                                    arrowPosition="right"
                                                    disabled={!canBeTranslated}
                                                    tabIndex={-1}
                                                    renderTrigger={props => {
                                                        const isPending = pendingTranslations.indexOf(site.language) > -1;
                                                        return (
                                                            <button className={styles.translate} aria-label={i18n('Translate this field')} disabled={isPending} {...props}>
                                                                {isPending ? (
                                                                    <Loader fill="currentColor" style={{
                                                                        width: 22,
                                                                        height: 22,
                                                                        marginLeft: -5,
                                                                        opacity: 0.75
                                                                    }}/>
                                                                ) : (
                                                                    <GlobeSvg/>
                                                                )}
                                                            </button>
                                                        );
                                                    }}
                                                >
                                                    {possibleTranslations.map(language => {
                                                        const fromSite = find(sites, { language: language });
                                                        if (!fromSite || (!value[fromSite.language] || null)) {
                                                            return null;
                                                        }
                                                        return (
                                                            <button key={`translate-${fromSite.uid}`} onClick={() => {
                                                                setPendingTranslations(pendingTranslations.concat(site.language));
                                                                api
                                                                    .translate({
                                                                        value: value[fromSite.language],
                                                                        fromSiteId: fromSite.id,
                                                                        toSiteId: site.id
                                                                    })
                                                                    .then(res => {
                                                                        const { status, data } = res;
                                                                        if (status === 200 && data && data.result) {
                                                                            if (!onChange) {
                                                                                return;
                                                                            }
                                                                            onChange({
                                                                                ...value,
                                                                                [site.language]: data.result
                                                                            });
                                                                        }
                                                                    })
                                                                    .catch(error => {
                                                                        console.error(error);
                                                                    })
                                                                    .finally(() => {
                                                                        setPendingTranslations(pendingTranslations.filter(language => language !== site.language));
                                                                    });
                                                            }}>{i18n('Translate from {siteName}', { siteName: fromSite.name })}</button>
                                                        );
                                                    })}
                                                </PopoutMenu>
                                            </>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        </div>);
                })}
            </div>
            {instructions ? (
                <div className={styles.instructions} dangerouslySetInnerHTML={{ __html: instructions }} />
            ) : null}
        </fieldset>
    );
};

export default MultiLangTextInput;
