import React  from 'react';
import i18n from "../i18n";

import { copyToClipboard, formatBytes, formatTimestamp, truncateFilename } from "../helpers";

import styles from '../styles/fileeditor-meta.module.scss';

import Tooltip from './Tooltip';
import LangSelectButton from "./LangSelectButton";
import FavoriteToggle from "./FavoriteToggle";

const BulkViewMeta = ({ files }) => {
    return (
        <div>
            {[{
                label: i18n('Selected files'),
                value: files.length
            }].map((column, index) => (
                <div key={index}>
                    <div style={{
                        fontSize: 10,
                        paddingBottom: 3
                    }}>
                        <span>{column.label}</span>
                    </div>
                    <div style={{
                        fontSize: 12,
                        fontWeight: 500
                    }}>
                        <span dangerouslySetInnerHTML={{ __html: column.value }}/>
                    </div>
                </div>
            ))}
        </div>
    );
};

const SingleViewMeta = ({ data }) => (
    <div>
        {[{
            label: i18n('Filesize'),
            value: formatBytes(data.size)
        }, {
            label: i18n('Dimensions'),
            value: `${data.width}x${data.height}`
        }, {
            label: i18n('Capture Date'),
            value: formatTimestamp(data.captureDate, 'dd/mm/yyyy')
        }, {
            label: i18n('Date Uploaded'),
            value: formatTimestamp(data.dateCreated, 'dd/mm/yyyy')
        }, {
            label: i18n('Orientation'),
            value: i18n(`${data.width === data.height ? 'Square' : (data.width >= data.height ? 'Landscape' : 'Portrait')}`)
        }, {
            label: i18n('Uploaded by'),
            value: (data.uploadedBy ? data.uploadedBy.name : null) || '&nbsp;',
        }, {
            label: i18n('Original Filename'),
            value: truncateFilename(data.originalFilename || data.filename, 25)
        }].map((column, index) => (
            <div key={index}>
                <div style={{
                    fontSize: 10,
                    paddingBottom: 3
                }}>
                    <span>{column.label}</span>
                </div>
                <div style={{
                    fontSize: 12,
                    fontWeight: 500
                }}>
                    <span dangerouslySetInnerHTML={{ __html: column.value }}/>
                </div>
            </div>
        ))}
    </div>
);

const ColorPalette = ({ palette }) => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <p className="u-sr-only">{i18n('Dominant Colors')}</p>
            <div style={{
                display: 'flex',
                width: 'calc(100% + 6px)',
                marginLeft: -3
            }}>
                {palette.map(color => (
                    <span key={`color-${color}`}>
                        <button
                            aria-label={i18n('Click to copy {color}', { color })}
                            className={styles.palettecolor}
                            style={{
                                backgroundColor: color
                            }}
                            onClick={() => copyToClipboard(color)}
                            data-tip={i18n('Click to copy {color}', { color })}
                        />
                        <Tooltip place="bottom"/>
                    </span>
                ))}
            </div>
        </div>
    );
};

const FileEditorMeta = ({ data, files }) => {

    const isSingleView = !!data;

    return (
        <div className={styles.root}>
            <div className={styles.lang}>
                <LangSelectButton/>
            </div>
            <div className={styles.scroller}>
                {isSingleView ? (<SingleViewMeta data={data}/>) : (<BulkViewMeta files={files}/>)}
            </div>
            {isSingleView ? (
                <div className={styles.palette}>
                    {isSingleView ? (
                        <FavoriteToggle
                            assetId={data.id}
                            isFavorite={data.isFavorite}
                            style={{ width: 24, marginRight: 20, flex: '0 0 auto' }}
                            strokeWidth={2}
                        />
                    ) : null}
                    <div style={{
                        paddingRight: 20,
                        flex: '0 1 auto',
                        whiteSpace: 'nowrap',
                        lineHeight: 1.2
                    }}>
                        <div style={{
                            fontSize: 10,
                            paddingBottom: 3
                        }}>
                            <span>{i18n('In Use')}</span>
                        </div>
                        <div style={{
                            fontSize: 12,
                            fontWeight: 500
                        }}>
                            <span>0 times</span>
                        </div>
                    </div>
                    {(data.colorPalette || []).length ? (
                        <ColorPalette palette={data.colorPalette}/>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default FileEditorMeta;
