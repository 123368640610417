import dateFormat from 'dateformat';
import prettyBytes from 'pretty-bytes';

export const CONTEXT_FIELD = 'field';

export const getUrlParam = name => {
    name = name.replace(/\[/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getContext = () => getUrlParam('context');

const GLOBAL_LOCALSTORAGE_KEYS = ['selectedSiteId', 'fileBrowser.orderBy', 'fileBrowser.sortOrder', 'fileBrowser.tilePreviewSize', 'app.sidebarPaneInitialSize'];

export const getLocalStorageKey = key => {
    if (GLOBAL_LOCALSTORAGE_KEYS.indexOf(key) > -1) {
        return key;
    }
    const storageKey = getUrlParam('storageKey');
    if (storageKey) {
        return `${key}.${storageKey}`;
    }
    return key;
};

export const setSessionStorage = (key, value) => {
    if (!window.sessionStorage) {
        return;
    }
    key = getLocalStorageKey(key);
    if (!value && value !== 0 && value !== false) {
        sessionStorage.removeItem(key);
        return;
    }
    sessionStorage.setItem(key, value);
};

export const getSessionStorage = key => window.sessionStorage ? sessionStorage.getItem(getLocalStorageKey(key)) : null;

export const setLocalStorage = (key, value) => {
    if (!window.localStorage) {
        return;
    }
    key = getLocalStorageKey(key);
    if (!value && value !== 0 && value !== false) {
        localStorage.removeItem(key);
        return;
    }
    localStorage.setItem(key, value);
};

export const getLocalStorage = key => window.localStorage ? localStorage.getItem(getLocalStorageKey(key)) : null;

export const triggerEvent = (name, target = window) => {
    if (typeof (Event) === 'function') {
        // modern browsers
        target.dispatchEvent(new Event(name));
    } else {
        // for IE and other old browsers
        // causes deprecation warning on modern browsers
        const event = window.document.createEvent('UIEvents');
        event.initUIEvent(name, true, false, window, 0);
        target.dispatchEvent(event);
    }
};

export const fitRect = (rect, bounds) => {
    const rectRatio = rect.width / rect.height;
    const boundsRatio = bounds.width / bounds.height;
    const newDimensions = {};
    if (rectRatio > boundsRatio) {
        // Fit to width
        newDimensions.width = bounds.width;
        newDimensions.height = rect.height * (bounds.width / rect.width);
    } else {
        // Fit to height
        newDimensions.width = rect.width * (bounds.height / rect.height);
        newDimensions.height = bounds.height;
    }
    return newDimensions;
};

export const formatTimestamp = (timestamp, format) => dateFormat(new Date(timestamp * 1000), format);

export const formatBytes = bytes => prettyBytes(parseFloat(bytes));

export const stripExtension = filename => filename.split('.').slice(0, -1).join('.') || filename;

export const truncateFilename = (filename, length = 8) => {
    const temp = filename.split('.');
    const extension = temp.length > 1 ? temp[temp.length - 1] : null;
    const name = (extension ? temp.slice(0, -1) : temp).join('.');
    if (name.length > length) {
        return `${name.substring(0, length)}...${extension}`;
    }
    return filename;
};

export const truncateString = (string, length, suffix = '...') => {
    if (string.length > (length - suffix.length)) {
        return string.slice(0, length).trim() + suffix;
    } else {
        return string;
    }
}

// export const validateEmail = email => {
//     var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return re.test(String(email).toLowerCase());
// };

export const copyToClipboard = (text, onSuccess) => {
    if (!navigator.clipboard) {
        const input = document.createElement('textarea');
        input.value = text;
        document.body.appendChild(input);
        input.focus();
        input.select();
        try {
            if (!document.execCommand('copy')) {
                console.warn('Unable to copy text');
            }
        } catch (error) {
            console.error(error);
        }
        document.body.removeChild(input);
        return;
    }
    navigator.clipboard.writeText(text).then(() => {
        if (onSuccess) {
            onSuccess();
        }
    }, error => {
        console.error(error);
    });
};

export const hexToRgb = hex => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

export const postParentMessage = data => {
    if (!window.parent) {
        console.warn('Tried to do parent.postMessage, but no parent present');
        return;
    }
    window.parent.postMessage(data, '*');
};
