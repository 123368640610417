import React from 'react';

import Dialog from "../Dialog";
import Button from '../Button';

import styles from "../../styles/dialog-basic.module.scss";

const BasicDialog = ({ title, text, children, confirmLabel = 'OK', onConfirm, cancelLabel = 'Cancel', onCancel, onDangerousConfirm, dangerousLabel, style = {} }) => {
    return (
        <Dialog>
            <div className={styles.dialog} style={style}>
                {/* Text/content */}
                {title || text || children ? (
                    <div>
                        {title ? (<h2 className={styles.title}>{title}</h2>) : null}
                        {text ? (
                            <div className={styles.text}>
                                {React.isValidElement(text) ? text : (<p dangerouslySetInnerHTML={{ __html: text }}/>)}
                            </div>
                        ) : null}
                        {children}
                    </div>
                ) : null}
                {/* Actions */}
                {onConfirm || onCancel || (dangerousLabel && onDangerousConfirm) ? (
                    <div className={styles.actions}>
                        <div className={styles.confirm}>
                            {onCancel ? (
                                <Button label={cancelLabel} onClick={onCancel}/>
                            ) : null}
                            {onConfirm ? (
                                <Button submit={true} label={confirmLabel} onClick={onConfirm}/>
                            ) : null}
                        </div>
                        {dangerousLabel && onDangerousConfirm ? (
                            <div className={styles.dangerous}>
                                <button onClick={onDangerousConfirm}>{dangerousLabel}</button>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </Dialog>
    );
};

export default BasicDialog;
