import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import get from 'lodash/get';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';

import Loader from './Loader';

import SplitLayout from './SplitLayout';

import { setSessionStorage, getSessionStorage } from '../helpers';

import ErrorFrown from "./ErrorFrown";
import Button from "./Button";
import FileEditorImage from "./FileEditorImage";
import PopoutMenu from "./PopoutMenu";
import ButtonGroup from "./ButtonGroup";
import FileEditorMeta from "./FileEditorMeta";
import FileEditorContent from "./FileEditorContent";

import BasicDialog from "./dialogs/BasicDialog";

import * as api from '../api';

import i18n from "../i18n";

import { fileBrowserUpdateFiles } from "../store";

import { ReactComponent as ArrowSvg } from '../img/arrow-down.svg';
import { ReactComponent as BulkEditSvg } from '../img/icon-bulk-edit.svg';

import styles from '../styles/fileeditor.module.scss';
import Tooltip from "./Tooltip";
import FileEditorVideo from './FileEditorVideo';

class FileEditorSingle extends Component {

    constructor(props) {
        super(props);

        this.ids = get(props, 'match.params.ids', '').split(',').filter(id => !!id).map(id => parseInt(id, 10));
        this.sessionStorageKey = `fileEditor-${this.ids.join(',')}`;

        this.save = this.save.bind(this);
        this.setData = this.setData.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.confirmClose = this.confirmClose.bind(this);

        this.state = {
            data: null,
            currentIndex: parseInt(getSessionStorage(`${this.sessionStorageKey}.currentIndex`) || 0, 10),
            lang: null,
            isSaving: false,
            hasUnsavedChanges: false,
            errorAlert: null,
            dialog: null
        };

    }

    fetchData() {

        const { dispatch, sites, selectedSiteId } = this.props;
        const { currentIndex } = this.state;

        if (!sites) {
            console.warn('No sites', { sites });
            return;
        }

        const assetId = this.ids[currentIndex] || null;

        if (!assetId) {
            console.warn('Invalid asset ID', { assetId });
            return;
        }

        // Fetch data for all sites
        const promises = sites.map(site => api.getFile({ assetId, siteId: site.id }));

        Promise
            .all(promises)
            .then(responses => {
                let data = {};
                for (let i = 0; i < responses.length; ++i) {
                    const res = responses[i];
                    const { status, data: asset } = res;
                    if (status === 200 && asset.uid) {
                        data = {
                            ...data,
                            [asset.language]: asset
                        };
                    } else {
                        this.setState({
                            data: false
                        });
                        console.warn('Failed to fetch Asset', { res });
                        return;
                    }
                }
                // Update the assets data in the browser (only for the selected site
                const currentSite = find(sites, { id: selectedSiteId }, sites[0]);
                const assetForCurrentSite = data[currentSite.language] || null;
                if (assetForCurrentSite) {
                    dispatch(fileBrowserUpdateFiles({ files: [assetForCurrentSite]}));
                }
                this.setState({
                    data: {
                        ...data
                    },
                    hasUnsavedChanges: false
                });
            })
            .catch(error => {
                this.setState({ data: false });
                console.error(error);
            });
    }

    confirmClose(onConfirm) {
        const { hasUnsavedChanges } = this.state;
        if (!hasUnsavedChanges) {
            onConfirm();
            return;
        }
        this.setState({
            dialog: {
                title: i18n('You have unsaved changes'),
                text: i18n('Are you sure you want to navigate away without saving? You will lose any unsaved changes.'),
                onConfirm: () => {
                    this.setState({ dialog: null });
                    onConfirm();
                },
                onCancel: () => {
                    this.setState({ dialog: null })
                }
            }
        });
    }

    close() {
        const { history } = this.props;
        this.confirmClose(() => history.push('/'));
    }

    next() {
        const { currentIndex } = this.state;
        this.confirmClose(() => this.setState({
            currentIndex: currentIndex < this.ids.length - 1 ? currentIndex + 1 : 0
        }));
    }

    prev() {
        const { currentIndex } = this.state;
        this.confirmClose(() => this.setState({
            currentIndex: currentIndex > 0 ? currentIndex - 1 : this.ids.length - 1
        }));
    }

    setData(dataToMerge) {

        const { sites } = this.props;
        const languages = sites.map(site => site.language);
        const languagesToMerge = intersection(languages, Object.keys(dataToMerge));

        let data = {
            ...this.state.data
        };

        if (languagesToMerge.length) {
            // Merge for particular languages
            languagesToMerge.forEach(language => {
                data = {
                    ...data,
                    [language]: {
                        ...(data[language] || {}),
                        ...dataToMerge[language]
                    }
                };
            });
        } else {
            // Merge across all languages
            languages.forEach(language => {
                data = {
                    ...data,
                    [language]: {
                        ...(data[language] || {}),
                        ...dataToMerge
                    }
                };
            });
        }

        if (isEqual(data, this.state.data)) {
            return;
        }

        this.setState({
            data,
            hasUnsavedChanges: true
        });
    }

    save() {

        const { data, isSaving } = this.state;

        if (isSaving) {
            return;
        }

        this.setState({
            isSaving: true
        }, async () => {

            const { sites, selectedSiteId } = this.props;
            const languagesToSave = intersection(sites.map(site => site.language), Object.keys(data));

            const promises = languagesToSave.map(language => {

                const site = find(sites, { language });
                const siteData = data[language];

                let fields = {
                    altText: siteData.altText,
                    description: siteData.description,
                    credit: siteData.credit,
                    copyrightNotice: siteData.copyrightNotice,
                    usageRestrictions: siteData.usageRestrictions,
                    damFilename: siteData.damFilename,
                    suggestedKeywords: siteData.suggestedKeywords.length ? siteData.suggestedKeywords : null
                };

                // Only save relational fields for the current site version!
                if (site.id === selectedSiteId) {
                    fields = {
                        ...fields,
                        place: siteData.place.length ? siteData.place : null,
                        keywords: siteData.keywords.length ? siteData.keywords : null,
                        categories: siteData.categories.length ? siteData.categories : null
                    };
                }

                return api.saveFile({
                    assetId: siteData.id,
                    siteId: site.id,
                    focalPoint: siteData.focalPoint,
                    fields
                });

            });

            // Save it all
            Promise
                .all(promises)
                .then(responses => {
                    let success = true;
                    for (let i = 0; i < responses.length; ++i) {
                        const res = responses[i];
                        const { status, data: body } = res;
                        if (status === 200 && body && body.success) {
                            continue;
                        }
                        let { error } = body || {};
                        error = error || '';
                        this.setState({
                            errorAlert: error,
                            isSaving: false
                        });
                        success = false;
                        break;
                    }
                    if (!success) {
                        return;
                    }
                    // All OK!
                    this.setState({
                        hasUnsavedChanges: false,
                        isSaving: false
                    }, () => {
                        if (this.closeAfterSave) {
                            this.close();
                        } else if (this.nextAfterSave) {
                            this.next();
                        } else {
                            this.fetchData();
                        }
                    });
                })
                .catch(error => {
                    this.setState({
                        errorAlert: get(error, 'response.data.error') || error,
                        isSaving: false
                    });
                    console.error(error);
                })
                .finally(() => {
                    this.closeAfterSave = false;
                    this.nextAfterSave = false;
                });

        });

    }

    onKeyDown(e) {
        const key = e.which || e.keyCode || null;
        if((e.ctrlKey || e.metaKey) && key === 83) {
            e.preventDefault();
            this.save();
        }
    }

    onKeyUp(e) {
        const key = e.which || e.keyCode || null;
        if (key === 27) {
            this.close();
        } else if (key === 37) {
            this.prev();
        } else if (key === 39) {
            this.next();
        }
    }

    componentDidMount() {
        this.fetchData();
        window.addEventListener('keydown', this.onKeyDown);
        window.addEventListener('keyup', this.onKeyUp);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let doFetchData = false;
        if (this.state.currentIndex !== prevState.currentIndex) {
            setSessionStorage(`${this.sessionStorageKey}.currentIndex`, this.state.currentIndex);
            doFetchData = true;
        }
        if (doFetchData) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        setSessionStorage(`${this.sessionStorageKey}.currentIndex`, null);
        window.removeEventListener('keydown', this.onKeyDown);
        window.removeEventListener('keyup', this.onKeyUp);
    }

    render() {

        const { currentIndex, data, isSaving, errorAlert, dialog } = this.state;
        const { history } = this.props;

        const enableSubmit = !!data;

        const asset = data ? Object.values(data)[0] || null : null;

        return (
            <>
                {dialog !== null ? (
                    <BasicDialog {...dialog} />
                ) : null}
                {errorAlert !== null ? (
                    <BasicDialog title={i18n('An error occurred')} text={errorAlert || ''} onConfirm={() => {
                        this.setState({
                            errorAlert: null
                        });
                    }}/>
                ) : null}
                <div className={styles.root}>
                    {asset ? (
                        <FileEditorMeta data={asset} />
                    ) : null}
                    <div className={styles.main}>
                        {(() => {
                            if (data && asset) {
                                return (
                                    <SplitLayout
                                        percentage={true}
                                        primaryIndex={0}
                                        primaryMinSize={25}
                                        secondaryMinSize={25}
                                        secondaryInitialSize={parseFloat(getSessionStorage('fileeditorSingle.secondaryPaneInitialSize')) || 66}
                                        onResize={size => {
                                            setSessionStorage('fileeditorSingle.secondaryPaneInitialSize', size);
                                        }}
                                    >
                                        <div className={styles.contentwrap}>
                                            <div className={styles.content}>
                                                <FileEditorContent key={`content-${asset.uid}`} data={data} setState={this.setData}/>
                                            </div>
                                        </div>
                                        <div className={styles.editor}>
                                            {asset.kind === 'video' ? (
                                                <FileEditorVideo key={asset.path} video={asset} setState={this.setData}/>
                                            ) : (
                                                <FileEditorImage key={asset.path} image={asset} setState={this.setData}/>
                                            )}
                                        </div>
                                    </SplitLayout>
                                );
                            } else if (data === null) {
                                return (<Loader/>);
                            } else {
                                return (<ErrorFrown text={i18n('Could not load file')} opacity={1}/>);
                            }
                        })()}
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.rating}>
                            {this.ids.length > 1 ? (
                                <div className={styles.modebtn}>
                                    <button aria-label={i18n('Switch to Bulk Edit mode')} onClick={() => {
                                        this.confirmClose(() => history.replace(`/bulk-edit/${this.ids.join(',')}`));
                                    }} data-tip={i18n('Bulk Edit')}>
                                        <BulkEditSvg fill="currentColor"/>
                                    </button>
                                    <Tooltip place="top"/>
                                </div>
                            ) : null}
                        </div>
                        <div className={styles.nav}>
                            {this.ids.length > 1 ? (
                                <div className={styles.nextprev}>
                                    <button aria-label={i18n('Previous')} className={styles.prevbtn} onClick={() => this.prev()}>
                                        <ArrowSvg stroke="currentColor"/>
                                    </button>
                                    <p>{i18n('{index} of {total}', { index: currentIndex + 1, total: this.ids.length })}</p>
                                    <button aria-label={i18n('Next')} className={styles.nextbtn} onClick={() => this.next()}>
                                        <ArrowSvg stroke="currentColor"/>
                                    </button>
                                </div>
                            ) : null}
                        </div>
                        {/* Actions */}
                        <div className={styles.actions}>
                            <Button onClick={() => { this.close(); }} label="Cancel"/>
                            <ButtonGroup submit={true} disabled={!enableSubmit}>
                                {this.ids.length > 1 && currentIndex < this.ids.length - 1 ? (
                                    <Button onClick={() => {
                                        this.nextAfterSave = true;
                                        this.save();
                                    }} showLoader={isSaving}>{i18n('Save and Next')}</Button>
                                ) : (
                                    <Button onClick={() => {
                                        this.closeAfterSave = true;
                                        this.save();
                                    }} showLoader={isSaving}>{i18n('Save and Close')}</Button>
                                )}
                                <PopoutMenu position="top right">
                                    {(() => {
                                        if (this.ids.length > 1 && currentIndex < this.ids.length - 1) {
                                            // Multiple images, current is not the last
                                            return (
                                                <>
                                                    <button onClick={this.save}>{i18n('Save and Continue Editing')}</button>
                                                    <button onClick={() => {
                                                        this.closeAfterSave = true;
                                                        this.save();
                                                    }}>{i18n('Save and Close')}</button>
                                                </>
                                            );
                                        } else if (this.ids.length >= 2 && currentIndex >= this.ids.length - 1) {
                                            // Multiple images, current *is* the last
                                            return (
                                                <>
                                                    <button onClick={this.save}>{i18n('Save and Continue Editing')}</button>
                                                    <button onClick={() => {
                                                        this.nextAfterSave = true;
                                                        this.save();
                                                    }}>{i18n('Save and Next')}</button>
                                                </>
                                            );
                                        }
                                        // Single image
                                        return (
                                            <button onClick={this.save}>{i18n('Save and Continue Editing')}</button>
                                        );
                                    })()}
                                </PopoutMenu>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default withRouter(connect(state => ({
    sites: state.sites,
    selectedSiteId: state.selectedSiteId
}))(FileEditorSingle));
