import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import {
    getSessionStorage,
    setSessionStorage,
    getLocalStorage,
    setLocalStorage,
    getContext,
    getUrlParam
} from "./helpers";

import {getAllowedExtensions, getAllowedKinds} from './settings';

// Initial state
export const initialState = {
    context: getContext(),
    token: getUrlParam('token'),
    user: null,
    csrfToken: null,
    sites: null,
    selectedSiteId: parseInt(getLocalStorage('selectedSiteId'), 10) || null,
    settings: null,
    uploadQueue: null,
    isAjaxLoading: null,
    isGoogleMapsApiLoaded: false,
    countNeedsReview: null,
    categories: null,
    dialog: null,
    fileBrowser: {
        files: null,
        meta: null,
        categories: null,
        selectedCategories: JSON.parse(getSessionStorage('fileBrowser.selectedCategories')),
        selectedUploaders: JSON.parse(getSessionStorage('fileBrowser.selectedUploaders')),
        selectedFilters: JSON.parse(getSessionStorage('fileBrowser.selectedFilters')),
        selectedFlags: JSON.parse(getSessionStorage('fileBrowser.selectedFlags')),
        selectedKinds: JSON.parse(getSessionStorage('fileBrowser.selectedKinds')),
        selectedIds: JSON.parse(getSessionStorage('fileBrowser.selectedIds')),
        orderBy: getSessionStorage('fileBrowser.orderBy') || 'dateUploaded',
        sortOrder: getSessionStorage('fileBrowser.sortOrder') || 'DESC',
        searchQuery: getSessionStorage('fileBrowser.searchQuery'),
        tilePreviewSize: parseFloat(getSessionStorage('fileBrowser.tilePreviewSize') || 50),
        isFetching: false
    },
    allowedExtensions: getAllowedExtensions(),
    allowedKinds: getAllowedKinds()
};

// Action types and action creators
export const actionTypes = {
    SET_CONTEXT: 'SET_CONTEXT',
    SET_TOKEN: 'SET_TOKEN',
    SET_USER: 'SET_USER',
    SET_CSRF_TOKEN: 'SET_CSRF_TOKEN',
    SET_SITES: 'SET_SITES',
    SET_SELECTED_SITE_ID: 'SET_SELECTED_SITE_ID',
    SET_SETTINGS: 'SET_SETTINGS',
    SET_IS_AJAX_LOADING: 'SET_IS_AJAX_LOADING',
    SET_IS_GOOGLE_MAPS_API_LOADED: 'SET_IS_GOOGLE_MAPS_API_LOADED',
    SET_COUNT_NEEDS_REVIEW: 'SET_COUNT_NEEDS_REVIEW',
    FILE_UPLOAD_ADD_TO_QUEUE: 'FILE_UPLOAD_ADD_TO_QUEUE',
    FILE_UPLOAD_RESOLVE_CONFLICTS: 'FILE_UPLOAD_RESOLVE_CONFLICTS',
    FILE_UPLOAD_START: 'FILE_UPLOAD_START',
    FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
    FILE_UPLOAD_PROGRESS: 'FILE_UPLOAD_PROGRESS',
    FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE',
    FILE_UPLOAD_CLEAR_QUEUE: 'FILE_UPLOAD_CLEAR_QUEUE',
    FILE_BROWSER_SET_FILES: 'FILE_BROWSER_SET_FILES',
    FILE_BROWSER_SET_SELECTED_IDS: 'FILE_BROWSER_SET_SELECTED_IDS',
    FILE_BROWSER_CLEAR_SELECTED_IDS: 'FILE_BROWSER_CLEAR_SELECTED_IDS',
    FILE_BROWSER_SET_ORDER_BY: 'FILE_BROWSER_SET_ORDER_BY',
    FILE_BROWSER_SET_SORT_ORDER: 'FILE_BROWSER_SET_SORT_ORDER',
    FILE_BROWSER_SET_SEARCH_QUERY: 'FILE_BROWSER_SET_SEARCH_QUERY',
    FILE_BROWSER_REMOVE_FILES_BY_ID: 'FILE_BROWSER_REMOVE_FILES_BY_ID',
    FILE_BROWSER_UPDATE_FILES_BY_ID: 'FILE_BROWSER_UPDATE_FILES_BY_ID',
    FILE_BROWSER_SET_CATEGORIES: 'FILE_BROWSER_SET_CATEGORIES',
    FILE_BROWSER_SET_SELECTED_CATEGORIES: 'FILE_BROWSER_SET_SELECTED_CATEGORIES',
    FILE_BROWSER_SET_UPLOADERS: 'FILE_BROWSER_SET_UPLOADERS',
    FILE_BROWSER_SET_SELECTED_UPLOADERS: 'FILE_BROWSER_SET_SELECTED_UPLOADERS',
    FILE_BROWSER_SET_SELECTED_FILTERS: 'FILE_BROWSER_SET_SELECTED_FILTERS',
    FILE_BROWSER_SET_SELECTED_FLAGS: 'FILE_BROWSER_SET_SELECTED_FLAGS',
    FILE_BROWSER_SET_SELECTED_KINDS: 'FILE_BROWSER_SET_SELECTED_KINDS',
    FILE_BROWSER_SET_TILE_PREVIEW_SIZE: 'FILE_BROWSER_SET_TILE_PREVIEW_SIZE',
    FILE_BROWSER_SET_IS_FETCHING: 'FILE_BROWSER_SET_IS_FETCHING',
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_DIALOG: 'SET_DIALOG'
};

export const setContext = context => ({
    type: actionTypes.SET_CONTEXT,
    context
});

export const setToken = token => ({
    type: actionTypes.SET_TOKEN,
    token
});

export const setUser = user => ({
    type: actionTypes.SET_USER,
    user
});

export const setCsrfToken = token => ({
    type: actionTypes.SET_CSRF_TOKEN,
    token
});

export const setSites = sites => ({
    type: actionTypes.SET_SITES,
    sites
});

export const setSelectedSite = ({ siteId }) => ({
    type: actionTypes.SET_SELECTED_SITE_ID,
    siteId
});

export const setSettings = settings => ({
    type: actionTypes.SET_SETTINGS,
    settings
});

export const setIsAjaxLoading = value => ({
    type: actionTypes.SET_IS_AJAX_LOADING,
    value
});

export const setIsGoogleMapsApiLoaded = value => ({
    type: actionTypes.SET_IS_GOOGLE_MAPS_API_LOADED,
    value
});

export const setCountNeedsReview = count => ({
    type: actionTypes.SET_COUNT_NEEDS_REVIEW,
    count
});

export const addFilesToQueue = ({ files }) => ({
    type: actionTypes.FILE_UPLOAD_ADD_TO_QUEUE,
    files
});

export const clearUploadQueue = () => ({
    type: actionTypes.FILE_UPLOAD_CLEAR_QUEUE
});

export const resolveUploadConflicts = ({ uploads }) => ({
    type: actionTypes.FILE_UPLOAD_RESOLVE_CONFLICTS,
    uploads
});

export const onFileUploadStart = ({ file }) => ({
    type: actionTypes.FILE_UPLOAD_START,
    file
});

export const onFileUploadSuccess = ({ file, data }) => ({
    type: actionTypes.FILE_UPLOAD_SUCCESS,
    file,
    data
});

export const onFileUploadProgress = ({ file, progress }) => ({
    type: actionTypes.FILE_UPLOAD_PROGRESS,
    file,
    progress
});

export const onFileUploadError = ({ file, error }) => ({
    type: actionTypes.FILE_UPLOAD_FAILURE,
    file,
    error
});

export const fileBrowserSetFiles = ({ files, meta }) => ({
    type: actionTypes.FILE_BROWSER_SET_FILES,
    files,
    meta
});

export const fileBrowserUpdateFiles = ({ files }) => ({
    type: actionTypes.FILE_BROWSER_UPDATE_FILES_BY_ID,
    files
});

export const fileBrowserSelectIds = ({ assetIds }) => ({
    type: actionTypes.FILE_BROWSER_SET_SELECTED_IDS,
    assetIds
});

export const fileBrowserClearSelectedIds = () => ({
    type: actionTypes.FILE_BROWSER_SET_SELECTED_IDS,
    assetIds: null
});

export const fileBrowserSetOrderBy = orderBy => ({
    type: actionTypes.FILE_BROWSER_SET_ORDER_BY,
    orderBy
});

export const fileBrowserSetSortOrder = sortOrder => ({
    type: actionTypes.FILE_BROWSER_SET_SORT_ORDER,
    sortOrder
});

export const fileBrowserSetSearchQuery = searchQuery => ({
    type: actionTypes.FILE_BROWSER_SET_SEARCH_QUERY,
    searchQuery
});

export const fileBrowserRemoveFilesById = assetIds => ({
    type: actionTypes.FILE_BROWSER_REMOVE_FILES_BY_ID,
    assetIds
});

export const fileBrowserSetCategories = categories => ({
    type: actionTypes.FILE_BROWSER_SET_CATEGORIES,
    categories
});

export const fileBrowserSetSelectedCategories = categoryIds => ({
    type: actionTypes.FILE_BROWSER_SET_SELECTED_CATEGORIES,
    categoryIds
});

export const fileBrowserSetUploaders = uploaders => ({
    type: actionTypes.FILE_BROWSER_SET_UPLOADERS,
    uploaders
});

export const fileBrowserSetSelectedUploaders = uploaderIds => ({
    type: actionTypes.FILE_BROWSER_SET_SELECTED_UPLOADERS,
    uploaderIds
});

export const fileBrowserSetSelectedFilters = filters => ({
    type: actionTypes.FILE_BROWSER_SET_SELECTED_FILTERS,
    filters
});

export const fileBrowserSetSelectedFlags = flags => ({
    type: actionTypes.FILE_BROWSER_SET_SELECTED_FLAGS,
    flags
});

export const fileBrowserSetSelectedKinds = kinds => ({
    type: actionTypes.FILE_BROWSER_SET_SELECTED_KINDS,
    kinds
});

export const fileBrowserSetTilePreviewSize = size => ({
    type: actionTypes.FILE_BROWSER_SET_TILE_PREVIEW_SIZE,
    size
});

export const fileBrowserSetIsFetching = value => ({
    type: actionTypes.FILE_BROWSER_SET_IS_FETCHING,
    value
});

export const setCategories = categories => ({
    type: actionTypes.SET_CATEGORIES,
    categories
});

export const setDialog = dialog => ({
    type: actionTypes.SET_DIALOG,
    dialog
});

// REDUCER
export const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_CSRF_TOKEN:
            return {
                ...state,
                csrfToken: action.token
            };

        case actionTypes.SET_TOKEN:
            return {
                ...state,
                token: action.token
            };

        case actionTypes.SET_CONTEXT:
            return {
                ...state,
                context: action.context
            };

        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user
            };

        case actionTypes.SET_SITES:
            return {
                ...state,
                sites: action.sites,
                selectedSiteId: state.selectedSiteId && !isNaN(state.selectedSiteId) && find(action.sites, { id: parseInt(state.selectedSiteId, 10) }) ? state.selectedSiteId : (action.sites && action.sites.length ? action.sites[0].id : null)
            };

        case actionTypes.SET_SELECTED_SITE_ID:
            setLocalStorage('selectedSiteId', action.siteId ? action.siteId : null);
            return {
                ...state,
                selectedSiteId: parseInt(action.siteId, 10)
            };

        case actionTypes.SET_SETTINGS:
            return {
                ...state,
                settings: action.settings
            };

        case actionTypes.SET_IS_AJAX_LOADING:
            return {
                ...state,
                isAjaxLoading: !!action.value
            };

        case actionTypes.SET_IS_GOOGLE_MAPS_API_LOADED:
            return {
                ...state,
                isGoogleMapsApiLoaded: !!action.value
            };

        case actionTypes.SET_COUNT_NEEDS_REVIEW:
            return {
                ...state,
                countNeedsReview: action.count
            };

        case actionTypes.FILE_UPLOAD_ADD_TO_QUEUE:
            return {
                ...state,
                uploadQueue: (state.uploadQueue || []).concat(action.files.map(file => ({
                    file,
                    status: 'pending'
                })))
            };

        case actionTypes.FILE_UPLOAD_START:
            return {
                ...state,
                uploadQueue: state.uploadQueue.map(upload => {
                    if (!isEqual(upload.file, action.file)) {
                        return upload;
                    }
                    return {
                        file: action.file,
                        status: 'progress'
                    };
                })
            };
        case actionTypes.FILE_UPLOAD_SUCCESS:
            console.log(action.file.name, 'uploaded!');
            return {
                ...state,
                uploadQueue: state.uploadQueue.map(upload => {
                    if (!isEqual(upload.file, action.file)) {
                        return upload;
                    }
                    return {
                        file: action.file,
                        status: 'complete',
                        ...(action.data || {})
                    };
                })
            };
        case actionTypes.FILE_UPLOAD_FAILURE:
            console.log(action.file.name, 'failed');
            return {
                ...state,
                uploadQueue: state.uploadQueue.map(upload => {
                    if (!isEqual(upload.file, action.file)) {
                        return upload;
                    }
                    return {
                        file: action.file,
                        error: action.error,
                        status: 'failed'
                    };
                })
            };
        case actionTypes.FILE_UPLOAD_RESOLVE_CONFLICTS:
            return {
                ...state,
                uploadQueue: state.uploadQueue.map(upload => {
                    for (let i = 0; i < action.uploads.length; ++i) {
                        if (isEqual(action.uploads[i].file, upload.file)) {
                            return {
                                ...upload,
                                ...(action.uploads[i]),
                                conflict: null
                            };
                        }
                    }
                    return upload;
                })
            };
        case actionTypes.FILE_UPLOAD_CLEAR_QUEUE:
            console.info('queue cleared!');
            return {
                ...state,
                uploadQueue: null
            };

        case actionTypes.FILE_BROWSER_SET_FILES:
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    files: action.files,
                    meta: action.meta || null
                }
            };

        case actionTypes.FILE_BROWSER_UPDATE_FILES_BY_ID:
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    files: (state.fileBrowser.files || []).slice(0).map(file => ({
                        ...file,
                        ...(find(action.files, { id: file.id }, {}))
                    }))
                }
            };

        case actionTypes.FILE_BROWSER_SET_SELECTED_IDS:
            setSessionStorage('fileBrowser.selectedIds', action.assetIds ? JSON.stringify(action.assetIds) : null);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    selectedIds: action.assetIds ? action.assetIds.slice(0) : null
                }
            };

        case actionTypes.FILE_BROWSER_CLEAR_SELECTED_IDS:
            setSessionStorage('fileBrowser.selectedIds', null);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    selectedIds: null
                }
            };

        case actionTypes.FILE_BROWSER_SET_ORDER_BY:
            setSessionStorage('fileBrowser.orderBy', action.orderBy);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    orderBy: action.orderBy
                }
            };

        case actionTypes.FILE_BROWSER_SET_SORT_ORDER:
            setSessionStorage('fileBrowser.sortOrder', action.sortOrder || 'DESC');
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    sortOrder: action.sortOrder || 'DESC'
                }
            };

        case actionTypes.FILE_BROWSER_SET_SEARCH_QUERY:
            setSessionStorage('fileBrowser.searchQuery', action.searchQuery);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    searchQuery: action.searchQuery
                }
            };

        case actionTypes.FILE_BROWSER_REMOVE_FILES_BY_ID:
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    files: (state.fileBrowser.files || []).filter(file => action.assetIds.indexOf(file.id) === -1)
                }
            };

        case actionTypes.FILE_BROWSER_SET_CATEGORIES:
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    categories: action.categories
                }
            };

        case actionTypes.FILE_BROWSER_SET_SELECTED_CATEGORIES:
            setSessionStorage('fileBrowser.selectedCategories', action.categoryIds ? JSON.stringify(action.categoryIds) : null);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    selectedCategories: action.categoryIds
                }
            };

        case actionTypes.FILE_BROWSER_SET_UPLOADERS:
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    uploaders: action.uploaders
                }
            };

        case actionTypes.FILE_BROWSER_SET_SELECTED_UPLOADERS:
            setSessionStorage('fileBrowser.selectedUploaders', action.uploaderIds ? JSON.stringify(action.uploaderIds) : null);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    selectedUploaders: action.uploaderIds
                }
            };

        case actionTypes.FILE_BROWSER_SET_SELECTED_FILTERS:
            setSessionStorage('fileBrowser.selectedFilters', action.filters ? JSON.stringify(action.filters) : null);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    selectedFilters: action.filters
                }
            };

        case actionTypes.FILE_BROWSER_SET_SELECTED_FLAGS:
            setSessionStorage('fileBrowser.selectedFlags', action.flags ? JSON.stringify(action.flags) : null);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    selectedFlags: action.flags
                }
            };

        case actionTypes.FILE_BROWSER_SET_SELECTED_KINDS:
            setSessionStorage('fileBrowser.selectedKinds', action.kinds ? JSON.stringify(action.kinds) : null);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    selectedKinds: action.kinds
                }
            };

        case actionTypes.FILE_BROWSER_SET_TILE_PREVIEW_SIZE:
            setSessionStorage('fileBrowser.tilePreviewSize', action.size);
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    tilePreviewSize: action.size
                }
            };

        case actionTypes.FILE_BROWSER_SET_IS_FETCHING:
            return {
                ...state,
                fileBrowser: {
                    ...state.fileBrowser,
                    isFetching: !!action.value
                }
            };

        case actionTypes.SET_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            };

        case actionTypes.SET_DIALOG:
            return {
                ...state,
                dialog: action.dialog
            };


        default:
            console.warn(`Unknown action ${action.type}`);
            return state
    }
};

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug User-defined debug mode param
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */
export const makeStore = (initialState, options) => {
    return createStore(
        reducer,
        initialState,
        composeWithDevTools(applyMiddleware(thunkMiddleware))
    );
};

const store = makeStore();

export default store;
