import React, { useEffect, useState, useRef } from 'react';
import uuid from 'uuid/v1';
import TextAreaAutosize from 'react-textarea-autosize';

import styles from '../../styles/input/text-input.module.scss';

const TextInput = ({ type, label, name, value, error, locale, multiline, placeholder, postfix, onChange, readonly, autoComplete = 'off', autoCorrect = 'off', autoCapitalize = 'off', spellCheck = false, ...props }) => {

    const postfixRef = useRef(null);
    const inputRef = useRef(null);

    const [postfixWidth, setPostfixWidth] = useState(0);

    const inputId = `input-${name ? name : uuid()}`;

    useEffect(() => {
        const { current: postfixEl } = postfixRef;
        const { current: input } = inputRef;
        if (!postfixEl || !input) {
            return;
        }
        const { width } = postfixEl.getBoundingClientRect();
        setPostfixWidth(width);
    }, [postfix]);

    return (
        <div className={styles.root} {...props}>
            {label || locale ? (
                <div className={styles.header}>
                    {label ? (<label htmlFor={inputId}>{label}</label>) : null}
                    {locale ? (<div className={styles.locale}>{locale}</div>) : null}
                </div>
            ) : null}
            <div className={styles.input}>
                {multiline ? (
                    <TextAreaAutosize id={inputId} name={name} rows={2} value={value || ''}
                              onChange={onChange} readOnly={readonly}/>
                ) : (
                    <>
                        <input ref={inputRef} id={inputId} type={type || 'text'} name={name}
                               value={value || ''} onChange={onChange} readOnly={readonly}
                               autoComplete={autoComplete} autoCapitalize={autoCapitalize}
                               spellCheck={spellCheck} autoCorrect={autoCorrect}

                               placeholder={placeholder} style={{
                            paddingRight: postfixWidth ? postfixWidth + 10 : ''
                        }}/>
                        {postfix ? (
                            <div ref={postfixRef} className={styles.postfix}>{postfix}</div>
                        ) : null}
                    </>
                )}
                {error ? (
                    <div className={styles.error}>
                        <p>{error}</p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default TextInput;
