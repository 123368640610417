import React, { useState } from 'react';
import uuid from "uuid/v1";

import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import AsyncSelect from 'react-select/lib/Async';
import AsyncCreateableSelect from 'react-select/lib/AsyncCreatable';

import styles from '../../styles/input/tags-input.module.scss';
import SuggestedTagsDialog from "../dialogs/SuggestedTagsDialog";
import i18n from "../../i18n";

import { ReactComponent as AddSvg } from '../../img/add.svg';

import { ReactComponent as enFlagSvg } from "../../img/flag-icons/en.svg";
import { ReactComponent as svFlagSvg } from "../../img/flag-icons/sv.svg";
import { ReactComponent as nbFlagSvg } from "../../img/flag-icons/nb.svg";
import { ReactComponent as dkFlagSvg } from "../../img/flag-icons/dk.svg";
import { ReactComponent as deFlagSvg } from "../../img/flag-icons/de.svg";

import EditableTag from '../EditableTag';
import RemoveTagBtn from '../RemoveTagBtn';

const FLAGS = {
    en: enFlagSvg,
    sv: svFlagSvg,
    nb: nbFlagSvg,
    dk: dkFlagSvg,
    de: deFlagSvg
};

const TagsInput = ({ name, label, options = [], value = [], defaultOptions, loadOptions, clearable = false, multiple: isMulti = true, createable = false, onChange, onBlur, suggestionsDialog, style = {}, placeholder, language, instructions, editableTags = false, displayDropdownArrow = true, disabled: isDisabled = false }) => {

    const [state, setState] = useState({
        displaySuggestions: false
    });

    const inputId = `input-${name ? name : uuid()}`;
    const isAsync = !!loadOptions;
    const isCreateable = !!createable;
    const isClearable = !!clearable;

    const selectComponentProps = {
        'aria-labelledby': inputId,
        hideSelectedOptions: true,
        noOptionsMessage: () => null,
        loadingMessage: () => null,
        loadOptions,
        defaultOptions,
        options,
        isMulti,
        isClearable,
        isDisabled,
        value,
        onChange,
        onBlur,
        placeholder,
        classNamePrefix: 'tags-dropdown',
        components: {
            IndicatorSeparator: () => null,
            LoadingIndicator: () => null,
            MultiValueLabel: editableTags ? EditableTag : components.MultiValueLabel,
            MultiValueRemove: RemoveTagBtn,
            DropdownIndicator: displayDropdownArrow ? components.DropdownIndicator : () => null
        },
        styles: {
            container: (provided) => ({
                ...provided,
                fontSize: 14,
                lineHeight: 1.2
            }),
            control: (provided) => ({
                ...provided,
                border: 'none'
            }),
            input: (provided) => ({
                ...provided,
                fontSize: 12
            }),
            placeholder: (provided) => ({
                ...provided,
                fontSize: 12
            }),
            groupHeading: (provided) => ({
                ...provided,
                paddingBottom: 5
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: 100
            }),
            multiValue: (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: !parseInt(state.data.value, 10) ? 'rgba(195, 89, 70, 0.28)' : provided.backgroundColor
                };
            },
            option: (provided, state) => ({
                ...provided,
                fontSize: 12,
                lineHeight: 1.2,
                color: 'currentColor',
                backgroundColor: state.isFocused && !state.isSelected ? '#C8CACD' : 'transparent',
                cursor: state.isSelected ? 'default' : 'pointer',
                padding: '8px 28px 8px 14px',
                fontStyle: state.isSelected ? 'italic' : '',
                transition: 'background-color 0.3s'
            })
        }
    };

    if (label) {
        selectComponentProps['aria-labelledby'] = `#label-${inputId}`;
    }

    let SelectComponent;
    if (isAsync && isCreateable) {
        SelectComponent = AsyncCreateableSelect;
    } else if (isAsync) {
        SelectComponent = AsyncSelect;
    } else if (isCreateable) {
        SelectComponent = CreatableSelect;
    } else {
        SelectComponent = Select;
    }

    const hasSuggestions = !!suggestionsDialog && !!suggestionsDialog.options.length;
    const displaySuggestionsDialog = hasSuggestions && state.displaySuggestions;

    return (
        <>
            {displaySuggestionsDialog ? (
                <SuggestedTagsDialog
                    {...suggestionsDialog}
                    onCancel={() => setState({ ...state, displaySuggestions: false })}
                    onConfirm={({ selectedOptions, discardUnusedSuggestions }) => {
                        if (!onChange) {
                            return;
                        }
                        onChange((value || []).concat(selectedOptions.map(value => ({
                            value,
                            label: value
                        }))), { discardUnusedSuggestions });
                        setState({
                            ...state,
                            displaySuggestions: false
                        });
                    }}
                    confirmLabel={suggestionsDialog.confirmLabel || i18n('Add selected')}
                />
            ) : null}
            <div className={styles.root} style={{
                ...style
            }}>
                {label || hasSuggestions ? (
                    <div className={styles.header}>
                        {label ? (
                            <label id={`label-${inputId}`} className={styles.label}>{label}</label>
                        ) : null}
                        {hasSuggestions ? (
                            <button className={styles.suggestions} onClick={() => setState({ ...state, displaySuggestions: true })}>
                                <AddSvg width={14} height={14}/>
                                <span>{i18n('{count} suggested', { count: suggestionsDialog.options.length })}</span>
                            </button>
                        ) : null}
                    </div>
                ) : null}
                <div className={styles.inputwrap}>
                    <div className={styles.input}>
                        {language ? (
                            <div className={styles.flag}>
                                {(() => {
                                    const FlagComponent = FLAGS[language] || null;
                                    if (!FlagComponent) {
                                        return null;
                                    }
                                    return (<FlagComponent/>);
                                })()}
                            </div>
                        ) : null}
                        <div className={styles.select}>
                            <SelectComponent {...selectComponentProps}/>
                        </div>
                    </div>
                </div>
                {instructions ? (
                    <div className={styles.instructions} dangerouslySetInnerHTML={{ __html: instructions }} />
                ) : null}
            </div>
        </>
    );
};

export default TagsInput;
