import React from 'react';

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { triggerEvent } from "../helpers";

const SplitLayout = ({ children, onResize, ...props }) => (
    <SplitterLayout onSecondaryPaneSizeChange={size => {
        if (onResize) {
            onResize(size);
        }
        triggerEvent('resize', window);
    }} {...props}>
        {children}
    </SplitterLayout>
);

export default SplitLayout;
