import React, { useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import i18n from "../i18n";
import TextInput from "./inputs/TextInput";

import Button from "./Button";

import { ReactComponent as LogoSvg } from '../img/logo.svg';

import styles from '../styles/login.module.scss';

import * as api from '../api';
import { setCsrfToken, setUser } from "../store";

const Login = ({ dispatch }) => {

    const [state, setState] = useState({
        loginName: null,
        password: null,
        error: null,
        isSubmitting: false
    });

    const { loginName, password, isSubmitting, error } = state;
    const canSubmit = !isSubmitting && !!(loginName && password);

    const login = () => {
        setState({
            ...state,
            isSubmitting: true
        });
        api
            .login({ loginName, password })
            .then(res => {
                const { status, data } = res;
                if (status === 200 && data && data.csrfToken && data.user) {
                    dispatch(setCsrfToken(data.csrfToken));
                    dispatch(setUser(data.user));
                } else {
                    setState({
                        ...state,
                        isSubmitting: false,
                        error: data && data.error ? data.error : i18n('Could not log in')
                    });
                }
            })
            .catch(error => {
                const message = get(error, 'response.data.error');
                setState({
                    ...state,
                    isSubmitting: false,
                    error: message || i18n('Could not log in')
                });
            });
    };

    return (
        <div className={styles.root}>
            <div>
                <div className={styles.logo}>
                    <LogoSvg/>
                </div>
                <form onSubmit={e => {
                    e.preventDefault();
                    if (!canSubmit) {
                        return;
                    }
                    login();
                }}>
                    <TextInput label={i18n('Email')} name="loginName" type="email" value={loginName || ''} onChange={e => {
                        setState({
                            ...state,
                            loginName: e.target.value
                        });
                    }} error={get(state, 'errors.loginName')}/>
                    <TextInput label={i18n('Password')} name="password" type="password" value={password || ''} onChange={e => {
                        setState({
                            ...state,
                            password: e.target.value
                        });
                    }} error={get(state, 'errors.password')}/>
                    <div className={styles.submit}>
                        <Button submit={true} disabled={!canSubmit} type="submit">{i18n('Log in')}</Button>
                    </div>
                </form>
                {error ? (
                    <div className={styles.error}>
                        <p>{error}</p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default connect()(Login);
