import React, { useEffect, useState, useRef } from 'react';
import { fitRect } from "../helpers";
import MuxVideoPlayer from './MuxVideoPlayer';

const FileEditorVideo = ({ video, setState }) => {

    const videoContainerRef = useRef(null);
    const videoRef = useRef(null);

    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });

    const onResize = () => {
        const { current: imageContainer } = videoContainerRef;
        const { width, height } = fitRect(video, imageContainer.getBoundingClientRect());
        setDimensions({ width, height });
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            userSelect: 'none'
        }}>
            <div ref={videoContainerRef} style={{
                display: 'flex',
                position: 'relative',
                flex: '1 1 100%',
                height: '100%',
                visibility: dimensions.width ? '' : 'hidden'
            }}>
                <div ref={videoRef} style={{ margin: 'auto', position: 'relative', backgroundColor: 'rgba(255, 255, 255, 0.1)', ...dimensions }}>
                    {dimensions.width ? (
                        <MuxVideoPlayer
                            playbackId={video.muxPlaybackId}
                            controls
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%',
                                zIndex: 2
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default FileEditorVideo;
