import React, { Component } from 'react';
import { AsyncCreatable } from 'react-select';
import { connect } from 'react-redux';
import i18n from "../i18n";

import {
    fileBrowserSetSearchQuery, fileBrowserSetSelectedFilters
} from "../store";

import get from 'lodash/get';
import find from 'lodash/find';

import * as algolia from '../algolia';

class FileBrowserSearchBar extends Component {

    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
    }

    getGroupNameFromHandle(handle) {
        return ({
            keywords: i18n('Keywords'),
            places: i18n('Places')
        })[handle] || handle;
    };

    getSingularGroupNameFromHandle(handle) {
        return ({
            keywords: i18n('Keyword'),
            places: i18n('Place')
        })[handle] || handle;
    }

    getGroupedTagsFromAlgoliaHits(results) {

        const language = algolia.getLanguage();

        const groups = {
            places: find(results, { index: 'places' }, {}),
            keywords: find(results, { index: 'keywords' }, {})
        };

        const ret = [];

        Object.keys(groups).forEach(group => {
            const hits = get(groups[group], 'hits', []);
            if (!hits.length) {
                return;
            }
            ret.push({
                label: this.getGroupNameFromHandle(group),
                options: hits.map(hit => ({
                    value: hit.objectID,
                    label: hit[`title_${language}`],
                    title: hit[`title_${language}`],
                    group
                }))
            });
        });

        return ret;

    }

    getTagsForSearch(search) {
        return algolia
            .getTagsForSearch(search)
            .then(({ results }) => this.getGroupedTagsFromAlgoliaHits(results))
            .catch(error => {
                console.error(error);
            });
    }

    componentDidMount() {
        if (this.selectRef) {
            // Hack to avoid the first option always being focused when the menu opens - see https://github.com/JedWatson/react-select/pull/4080
            this.selectRef.select.select.select.getNextFocusedOption = () => null;
        }
    }

    render() {

        const { dispatch, searchQuery, selectedFilters } = this.props;

        return (
            <div onKeyUp={e => {
                // Hack to "close" the open menu when the user hits the enter key
                if (!this.selectRef) {
                    return;
                }
                const key = e.key || e.which || e.keyCode;
                if (['Enter', 13].indexOf(key) === -1) {
                    return;
                }
                this.selectRef.blur();
                this.selectRef.focus();
            }}>
                <label htmlFor="search" className="u-sr-only">{i18n('Search')}</label>
                <AsyncCreatable
                    ref={ref => this.selectRef = ref}
                    classNamePrefix="tags-dropdown"
                    isValidNewOption={() => false}
                    inputValue={searchQuery || ''}
                    value={(selectedFilters || []).map(filter => ({
                        value: filter.id,
                        label: `${this.getSingularGroupNameFromHandle(filter.group)}: ${filter.title}`,
                        title: filter.title,
                        group: filter.group
                    }))}
                    isClearable={false}
                    placeholder={i18n('Search')}
                    onInputChange={(value, { action }) => {
                        if (['menu-close', 'input-blur'].indexOf(action) > -1) {
                            return;
                        }
                        dispatch(fileBrowserSetSearchQuery(value));
                    }}
                    onChange={options => {
                        const filters = (options || []).map(option => ({
                            id: parseInt(option.value, 10),
                            title: option.title,
                            group: option.group
                        }));
                        dispatch(fileBrowserSetSelectedFilters(filters));
                    }}
                    tabSelectsValue={false}
                    isMulti={true}
                    loadOptions={search => this.getTagsForSearch(search)}
                    loadingMessage={() => null}
                    noOptionsMessage={() => null}
                    components={
                        {
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }
                    }
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            fontSize: 14,
                            lineHeight: 1.2
                        }),
                        groupHeading: (provided) => ({
                            ...provided,
                            paddingBottom: 5
                        }),
                        menu: (provided) => ({
                            ...provided,
                            margin: 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            fontSize: 14,
                            lineHeight: 1.2,
                            color: 'currentColor',
                            backgroundColor: state.isFocused && !state.isSelected ? '#C8CACD' : 'transparent',
                            cursor: state.isSelected ? 'default' : 'pointer',
                            padding: '8px 34px 8px 20px',
                            fontStyle: state.isSelected ? 'italic' : '',
                            transition: 'background-color 0.3s'
                        })
                    }}
                />
            </div>
        );
    }
}

export default connect(state => ({
    selectedSiteId: state.selectedSiteId,
    sites: state.sites,
    searchQuery: get(state, 'fileBrowser.searchQuery', ''),
    selectedFilters: get(state, 'fileBrowser.selectedFilters', [])
}))(FileBrowserSearchBar);
