import axios from "axios";
import { stringify } from 'qs';
import store from './store';

const csrfHeader = () => {
    return {
        'X-CSRF-Token': store.getState().csrfToken
    };
};

const authHeader = () => {
    const token = store.getState().token;
    if (!token) {
        return {};
    }
    return {
        'Authorization': `Bearer ${token}`
    };
};

const getAxios = () => axios.create({
    headers: {
        ...csrfHeader()
    },
    transformRequest: [(data) => stringify(data)]
});

export const getCsrfToken = () => getAxios()({
    method: 'get',
    url: '/actions/escape-dam-module/default/get-csrf-token'
});

export const validateCsrfToken = () => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/default/validate-csrf-token'
});

export const getSites = () => getAxios()({
    method: 'get',
    url: '/actions/escape-dam-module/default/get-sites'
});

export const getUser = () => getAxios()({
    method: 'get',
    url: '/actions/escape-dam-module/users/get-user',
    headers: {
        ...authHeader()
    }
});

export const login = ({ loginName, password }) => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/users/login',
    data: {
        loginName,
        password
    }
});

export const logout = () => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/users/logout'
});

export const getFile = ({ assetId, siteId }) => getAxios()({
    method: 'get',
    url: `/api/assets/${assetId}`,
    params: {
        siteId: siteId || store.getState().selectedSiteId
    }
});

export const getFiles = (params = {}, options = {}) => getAxios()({
    method: 'get',
    url: '/api/assets',
    params: {
        ...params,
        siteId: store.getState().selectedSiteId
    },
    ...options
});

export const getFilesByCriteria = criteria => getAxios()({
    method: 'post',
    url: '/api/assets',
    data: {
        siteId: store.getState().selectedSiteId,
        criteria
    }
});

export const toggleFavorite = ({ assetId, userId }) => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/files/toggle-favorite',
    data: {
        assetId,
        userId
    }
});

export const getCountNeedsReview = () => getAxios()({
    method: 'get',
    url: '/actions/escape-dam-module/files/get-count-needs-review',
    params: {
        siteId: store.getState().selectedSiteId
    }
});

export const uploadFile = file => {
    const formData = new FormData();
    formData.append('assets-upload', file);
    return axios({
        method: 'post',
        url: '/actions/escape-dam-module/files/upload',
        headers: {
            'Content-Type': 'multipart/form-data',
            ...csrfHeader()
        },
        data: formData
    });
};

export const uploadAndReplaceFile = ({ file, assetId }) => {
    const formData = new FormData();
    formData.append('assets-upload', file);
    formData.append('assetId', assetId);
    return axios({
        method: 'post',
        url: '/actions/escape-dam-module/files/replace-file',
        headers: {
            'Content-Type': 'multipart/form-data',
            ...csrfHeader()
        },
        data: formData
    });
};

export const replaceFile = ({ sourceAssetId, assetId }) => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/files/replace-file',
    data: {
        siteId: store.getState().selectedSiteId,
        sourceAssetId,
        assetId
    }
});

export const deleteFile = assetId => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/files/delete-file',
    data: {
        assetId
    }
});

export const deleteFiles = assetIds => assetIds.map(assetId => deleteFile(assetId));

export const saveFile = ({ assetId, siteId, fields = {}, ...props }) => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/files/save-file',
    data: {
        siteId: siteId || store.getState().selectedSiteId,
        assetId,
        fields,
        ...props
    }
});

export const bulkSaveFiles = ({ assetIds, siteId, fields = {}, ...props }) => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/files/bulk-save-files',
    data: {
        siteId: siteId || store.getState().selectedSiteId,
        assetIds,
        fields,
        ...props
    }
});

export const getKeywords = ({ search: q }) => getAxios()({
    method: 'get',
    url: '/api/keywords',
    params: {
        q,
        siteId: store.getState().selectedSiteId
    }
});

export const getCountries = ({ search: q }) => getAxios()({
    method: 'get',
    url: '/api/countries',
    params: {
        q,
        siteId: store.getState().selectedSiteId
    }
});

export const getPlaces = ({ search: q }) => getAxios()({
    method: 'get',
    url: '/api/places',
    params: {
        q,
        siteId: store.getState().selectedSiteId
    }
});

export const getCategories = () => getAxios()({
    method: 'get',
    url: '/api/categories',
    params: {
        siteId: store.getState().selectedSiteId
    }
});

export const getCategory = ({ categoryId, siteId }) => getAxios()({
    method: 'get',
    url: `/api/categories/${categoryId}`,
    params: {
        siteId: siteId || store.getState().selectedSiteId
    }
});

export const saveCategory = ({ categoryId, title, siteId }) => getAxios()({
    method: 'post',
    url: `/actions/escape-dam-module/categories/save-category`,
    data: {
        siteId: siteId || store.getState().selectedSiteId,
        categoryId,
        title
    }
});

export const getTag = ({ tagId, siteId }) => getAxios()({
    method: 'get',
    url: `/api/tags/${tagId}`,
    params: {
        siteId: siteId || store.getState().selectedSiteId
    }
});

export const saveTag = ({ tagId, title, siteId }) => getAxios()({
    method: 'post',
    url: `/actions/escape-dam-module/tags/save-tag`,
    data: {
        siteId: siteId || store.getState().selectedSiteId,
        tagId,
        title
    }
});

export const getTagsForSearch = ({ search: q, options = {} }) => getAxios()({
    method: 'get',
    url: '/api/search-tags',
    params: {
        q,
        siteId: store.getState().selectedSiteId
    },
    ...options
});

export const downloadFiles = ({ assetIds }) => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/files/download-files',
    responseType: 'blob',
    data: {
        assetIds,
        siteId: store.getState().selectedSiteId
    }
});

export const translate = ({ value, fromSiteId, toSiteId }) => getAxios()({
    method: 'post',
    url: '/actions/escape-dam-module/translations/translate',
    data: {
        value,
        fromSiteId,
        toSiteId
    }
});

export const getSettings = () => getAxios()({
    method: 'get',
    url: '/actions/escape-dam-module/default/get-settings'
});

export const getUploaders = () => getAxios()({
    method: 'get',
    url: '/api/uploaders'
});
