const MUX_STREAMING_DOMAIN = 'https://stream.mux.com';

const MUX_IMAGE_DOMAIN = 'https://image.mux.com';

export const MUX_KEY = 'd95959db-9062-413e-836d-5ab5f42e80f9';

export const getStreamingUrlForVideo = ({muxPlaybackId}) => muxPlaybackId ? `${MUX_STREAMING_DOMAIN}/${muxPlaybackId}.m3u8` : '';

export const getImageForVideo = ({muxPlaybackId}, params = {}) => {
    if (!muxPlaybackId) {
        return '';
    }
    if (!params.fit_mode) {
        params.fit_mode = params.width && params.height ? 'smartcrop' : 'crop';
    }
    const query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return `${MUX_IMAGE_DOMAIN}/${muxPlaybackId}/thumbnail.jpg?${query}`;
};
