import React, { useState, useEffect } from 'react';
import find from 'lodash/find';
import { components } from 'react-select';

import store from '../store';
import Popover from './Popover';
import * as api from '../api';

import MultiLangTextInput from "./inputs/MultiLangTextInput";
import Loader from "./Loader";

const EditableTag = (props) => {

    const { sites, selectedSiteId } = store.getState();

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [label, setLabel] = useState(props.data.label);

    const isNewTag = !parseInt(props.data.value, 10);

    useEffect(() => {
        const { value: id, type } = props.data;
        if (isNewTag) {
            return;
        }
        if (popoverOpen) {
            // Get 'em
            let promises;
            if (type === 'tag') {
                promises = sites.map(site => api.getTag({
                    tagId: id,
                    siteId: site.id
                }));
            } else if (type === 'category') {
                promises = sites.map(site => api.getCategory({
                    categoryId: id,
                    siteId: site.id
                }));
            } else {
                throw new Error(`Unknown tag type ${type}`);
            }
            Promise
                .all(promises)
                .then(res => res.reduce((carry, { data }) => ({
                    ...carry,
                    [data.language]: data.title
                }), {}))
                .then(data => setValue(data))
                .catch(error => {
                    console.error(error);
                });
        } else {
            // Save 'em
            if (value) {
                let promises = Object.keys(value).map(language => {
                    const site = find(sites, { language });
                    const title = value[language] || '';
                    if (!title || !title.replace(/\s/g,'').length) {
                        console.log('skipping this one', { title });
                        return null;
                    }
                    if (type === 'tag') {
                        return api.saveTag({
                            tagId: id,
                            siteId: site.id,
                            title
                        });
                    } else if (type === 'category') {
                        return api.saveCategory({
                            tagId: id,
                            siteId: site.id,
                            title
                        });
                    } else {
                        throw new Error(`Unknown tag type ${type}`);
                    }
                }).filter(promise => !!promise);
                if (promises.length) {
                    Promise.all(promises).catch(error => { console.error(error) });
                }
                const currentSite = find(sites, { id: selectedSiteId });
                if (value[currentSite.language]) {
                    setLabel(value[currentSite.language]);
                }
            }
            setValue(null);
        }
    }, [popoverOpen]);

    return (
        <Popover
            isOpen={popoverOpen}
            setOpen={setPopoverOpen}
            style={{
                width: 320,
                maxWidth: '100%',
                padding: 12,
                borderRadius: 6
            }}
            content={(
                <div style={{
                    position: 'relative'
                }}>
                    {!value ? (
                        <Loader style={{
                            width: 20,
                            height: 20
                        }}/>
                    ) : null}
                    <MultiLangTextInput
                        onChange={values => setValue({
                            ...(value || {}),
                            ...values
                        })}
                        onKeyUp={e => {
                            const key = e.which || e.keyCode || null;
                            if (key === 13) {
                                setPopoverOpen(false);
                            }
                        }}
                        disabled={!value}
                        value={value}
                        sites={sites}
                        style={{
                            opacity: value ? '' : 0
                        }}
                    />
                </div>
            )}
        >
            <button onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
            }} onDoubleClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setPopoverOpen(true);
            }} disabled={isNewTag}>
                <components.MultiValueLabel {...props}>
                    {label}
                </components.MultiValueLabel>
            </button>
        </Popover>
    );
};

export default EditableTag;
