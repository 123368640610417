import {getUrlParam} from './helpers';

export const FILE_KINDS = {
    image: {
        label: 'Images',
        extensions: ['jpg', 'jpeg', 'gif', 'png']
    },
    video: {
        label: 'Videos',
        extensions: ['mp4', 'mov', 'wmv'],
    }
}

export const getAllExtensions = () => Object.keys(FILE_KINDS).reduce((carry, key) => carry.concat(FILE_KINDS[key].extensions), []);

export const getAllowedExtensions = () => {
    const allowedExtensionsParam = getUrlParam('allowedExtensions');
    if (allowedExtensionsParam) {
        return allowedExtensionsParam.split(',');
    } else {
        const context = getUrlParam('context');
        if (context === 'field') {
            return FILE_KINDS.image.extensions;
        }
    }
    return getAllExtensions();
};

export const getAllowedKinds = () => {
    const allowedExtensions = getAllowedExtensions();
    return Object.keys(FILE_KINDS).reduce((carry, key) => FILE_KINDS[key].extensions.filter(extension => allowedExtensions.includes(extension)).length ? carry.concat(key) : carry, []);
};

