import React from 'react';

const ButtonGroup = ({ children, submit, disabled, style = {} }) => {
    const numChildren = children.length ? children.length : 1;
    return (
        <div style={{
            display: 'flex',
            position: 'relative',
            ...style
        }}>
            {React.Children.map(children, (button, index) => {
                const isFirst = index === 0;
                const isLast = index === numChildren - 1;
                let style = {};
                if (!isFirst) {
                    style = {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                    };
                }
                if (!isLast) {
                    style = {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                    };
                }
                if (!isLast && numChildren > 1) {
                    if (submit) {
                        style = {
                            ...style,
                            borderRight: '1px solid rgba(255, 255, 255, 0.25)'
                        };
                    } else {
                        style = {
                            ...style,
                            borderRight: 'none'
                        };
                    }
                }
                return React.cloneElement(button, { style, disabled, submit });
            })}
        </div>
    );
};

export default ButtonGroup;
