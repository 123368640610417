import React, { useState } from 'react';
import { connect } from 'react-redux';

import { fileBrowserUpdateFiles } from "../store";
import * as api from "../api";
import { ReactComponent as IconHeartSvg } from "../img/icon-heart.svg";

const FavoriteToggle = ({ isFavorite, assetId, userId, onChange, dispatch, ...props }) => {

    const [state, setState] = useState({
        isFavorite
    });

    return (
        <button onClick={() => {
            const shouldBeFavorite = !state.isFavorite;
            setState({
                isFavorite: shouldBeFavorite
            });
            dispatch(fileBrowserUpdateFiles({
                files: [{
                    id: assetId,
                    isFavorite: shouldBeFavorite
                }]
            }));
            if (onChange) {
                onChange(shouldBeFavorite);
            }
            api.toggleFavorite({
                assetId,
                userId
            })
                .then(res => {
                    const { status, data } = res;
                    if (status === 200 && data && data.assetId && data.isFavorite !== shouldBeFavorite) {
                        dispatch(fileBrowserUpdateFiles({
                            files: [{
                                id: parseInt(data.assetId, 10),
                                isFavorite: data.isFavorite
                            }]
                        }));
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }} {...props}>
            <IconHeartSvg stroke="currentColor" fill={state.isFavorite ? 'currentColor' : 'none'} strokeWidth={props.strokeWidth || 2}/>
        </button>
    );
};

export default connect(state => ({
    userId: state.user.id
}))(FavoriteToggle);
