import get from 'lodash/get';

const LANGUAGE_MAP = {
    nb: 'no'
};

let _placesApi = null;
let _autocompleteSessionToken = null;

const getPlacesApi = () => {
    _placesApi = _placesApi || get(window, 'google.maps.places', null);
    if (!_placesApi) {
        console.warn('Google Places API not loaded');
        return null;
    }
    return _placesApi;
};

const getAutocompleteSessionToken = () => {
    if (!_autocompleteSessionToken) {
        const api = getPlacesApi();
        if (!api) {
            return null;
        }
        _autocompleteSessionToken = new api.AutocompleteSessionToken();
    }
    return _autocompleteSessionToken;
};

export const loadGoogleMapsApi = (key, language, onLoad) => {
    if (window.google && window.google.maps) {
        window.google.maps = null;
    }
    _placesApi = null;
    _autocompleteSessionToken = null;
    const languageCode = LANGUAGE_MAP[language] || language;
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=${languageCode}`;
    script.async = true;
    script.onload = onLoad;
    document.body.appendChild(script);
    console.log('Loaded Google Maps API', { language });
};

export const getAutocompletePlaces = search => new Promise((resolve, reject) => {

    const api = getPlacesApi();

    if (!api) {
        reject('Google Maps API not loaded');
        return;
    }

    const sessionToken = getAutocompleteSessionToken();
    const service = new api.AutocompleteService();

    service.getPlacePredictions({
        input: search,
        sessionToken
    }, (results, status) => {
        if (status !== api.PlacesServiceStatus.OK) {
            reject(status);
            return;
        }
        resolve(results);
    });
});
