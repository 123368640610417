import React from 'react';
import classNames from 'classnames';
import Loader from './Loader';

import styles from '../styles/button.module.scss';

const Button = ({ submit, label, type, disabled, onClick, showLoader, children, style = {} }) => (
    <button className={classNames(styles.root, submit ? styles.submit : null)} style={{
        borderRadius: 3,
        fontSize: 14,
        lineHeight: 1.2,
        height: 40,
        padding: 0,
        fontWeight: 500,
        textAlign: 'center',
        opacity: (disabled || showLoader) ? 0.5 : '',
        cursor: (disabled || showLoader) ? 'default' : '',
        position: 'relative',
        whiteSpace: 'nowrap',
        userSelect: 'none',
        ...style
    }} onClick={disabled ? null : onClick} type={type}>
        {showLoader ? (
            <Loader style={{ width: 20, height: 20 }}/>
        ) : null}
        <span style={{
            display: 'block',
            padding: '0 16px',
            opacity: showLoader ? 0 : 1,
            transition: showLoader ? '' : 'opacity 0.3s ease-in'
        }}>{children || label}</span>
    </button>
);

export default Button;
