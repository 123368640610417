import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import ResizeObserver from 'resize-observer-polyfill';

import i18n from "../i18n";
import axios from 'axios';
import * as api from '../api';
import * as algolia from '../algolia';

import {
    fileBrowserSetFiles,
    fileBrowserSelectIds,
    fileBrowserSetCategories, fileBrowserSetIsFetching, fileBrowserSetUploaders
} from '../store';

import FileBrowserTiles from "./FileBrowserTiles";
import FileBrowserUi from "./FileBrowserUi";
import FileBrowserActions from "./FileBrowserActions";
import ErrorFrown from "./ErrorFrown";

import styles from '../styles/filebrowser.module.scss';
import FileBrowserContextMenu from "./FileBrowserContextMenu";
import UploadDropzone from "./UploadDropzone";

class FileBrowser extends Component {

    constructor(props) {
        super(props);
        this.currentPage = 1;
        this.filesRef = React.createRef();
        this.scrollRef = React.createRef();
        this.thumbsRef = React.createRef();
        this.toggleSelectFile = this.toggleSelectFile.bind(this);
        this.onFilesScroll = this.onFilesScroll.bind(this);
        this.onThumbsResize = this.onThumbsResize.bind(this);
        const doFetchFiles = this.fetchFiles.bind(this);
        this.fetchFiles = debounce(doFetchFiles, 150, { leading: false });
    }

    toggleSelectFile(id) {
        const { selectedIds, dispatch } = this.props;
        const assetIds = selectedIds && selectedIds instanceof Array ? selectedIds : [];
        const index = assetIds.indexOf(id);
        if (index > -1) {
            assetIds.splice(index, 1);
        } else {
            assetIds.push(id);
        }
        dispatch(fileBrowserSelectIds({ assetIds }));
    }

    fetchCategories() {
        const { dispatch } = this.props;
        api.getCategories()
            .then(({ data }) => data)
            .then(({ data: categories }) => dispatch(fileBrowserSetCategories(categories)))
            .catch(error => {
                console.error(error);
            });
    }

    fetchUploaders() {
        const { dispatch } = this.props;
        api.getUploaders()
            .then(({ data }) => data)
            .then(({ data: uploaders }) => dispatch(fileBrowserSetUploaders(uploaders)))
            .catch(error => {
                console.error(error);
            });
    }

    setFiles(newFiles, meta) {

        const { files, dispatch } = this.props;

        let allFiles;

        if (this.currentPage > 1) {
            allFiles = (files || []).slice(0).concat(newFiles || []);
        } else {
            this.filesRef.current.scrollTop = 0;
            allFiles = newFiles || [];
        }

        dispatch(fileBrowserSetFiles({
            files: allFiles,
            meta
        }));
    }

    fetchFiles() {

        if (this.fetchFilesCancelTokenSource) {
            this.fetchFilesCancelTokenSource.cancel();
        }

        const { orderBy, sortOrder, selectedCategories, selectedUploaders, selectedFilters, selectedFlags, selectedKinds, searchQuery, user, categories, uploaders, dispatch, allowedKinds } = this.props;

        if (categories === null || uploaders === null) {
            // Wait for the categories and uploaders
            return;
        }

        dispatch(fileBrowserSetIsFetching(true));

        const selectedCategoriesToFilterBy = (selectedCategories || []).filter(category => !!category);
        const selectedUploadersToFilterBy = (selectedUploaders || []).filter(uploader => !!uploader);
        const selectedFiltersToFilterBy = (selectedFilters || []).filter(filter => !!filter);
        const selectedFlagsToFilterBy = (selectedFlags || []).filter(flag => !!flag);
        const selectedKindsToFilterBy =  (selectedKinds || ['image', 'video']).filter(kind => !!kind && allowedKinds.indexOf(kind) > -1);

        // SEARCH – OFFLOADED TO ALGOLIA
        if (searchQuery && searchQuery.length >= 2) {

            const language = algolia.getLanguage();

            const params = {
                page: this.currentPage ? this.currentPage - 1 : 0
            };

            const filters = [];

            if (selectedCategoriesToFilterBy.length) {
                const categoryTitles = categories.reduce((carry, category) => {
                    if (selectedCategoriesToFilterBy.indexOf(category.id) > -1) {
                        return carry.concat(category.title);
                    }
                    return carry;
                }, []);
                if (categoryTitles.length) {
                    const categoryFilter = categoryTitles.map(categoryTitle => `categories_${language}:'${categoryTitle}'`).join(' OR ');
                    filters.push(`(${categoryFilter})`);
                }
            }

            if (selectedUploadersToFilterBy.length) {
                const uploaderIds = uploaders.reduce((carry, uploader) => {
                    if (selectedUploaders.indexOf(uploader.id) > -1) {
                        return carry.concat(uploader.id);
                    }
                    return carry;
                }, []);
                if (uploaderIds.length) {
                    const uploadersFilter = uploaderIds.map(uploaderId => `uploadedBy.id=${uploaderId}`).join(' OR ');
                    filters.push(`(${uploadersFilter})`);
                }
            }

            if (selectedFiltersToFilterBy.length) {
                const selectedKeywords = [];
                const selectedPlaces = [];
                selectedFiltersToFilterBy.forEach(filter => {
                    if (filter.group === 'keywords') {
                        selectedKeywords.push(filter.title);
                    } else if (filter.group === 'places') {
                        selectedPlaces.push(filter.title);
                    }
                });
                if (selectedKeywords.length) {
                    const keywordsFilter = selectedKeywords.map(keyword => `keywords_${language}:'${keyword}'`).join(' AND ');
                    filters.push(`(${keywordsFilter})`);
                }
                if (selectedPlaces.length) {
                    const placesFilter = selectedPlaces.map(place => `places_${language}:'${place}'`).join(' AND ');
                    filters.push(`(${placesFilter})`);
                }
            }

            if (selectedFlagsToFilterBy.length) {
                if (selectedFlagsToFilterBy.indexOf('favorites') > -1) {
                    filters.push(`favoritedBy=${user.id}`);
                }
                // if (selectedFlagsToFilterBy.indexOf('uploads') > -1) { // Uploads flag has been removed
                //     filters.push(`uploadedBy.id=${user.id}`);
                // }
            }

            if (selectedKindsToFilterBy.length === 1) {
                filters.push(`kind:${selectedKindsToFilterBy[0]}`);
            }

            if (filters.length) {
                params.filters = filters.join(' AND ');
            }

            algolia
                .fetchFiles(searchQuery, params)
                .then(res => {

                    const { nbHits: total, nbPages: totalPages, page: currentPage, hitsPerPage, hits } = res;

                    const meta = {
                        pagination: {
                            count: hits.length,
                            current_page: currentPage + 1,
                            per_page: hitsPerPage,
                            total,
                            totalPages
                        }
                    };

                    this.pagination = meta.pagination;
                    this.currentPage = this.pagination.current_page;

                    const files = hits.map(hit => ({
                        ...hit,
                        id: parseInt(hit.objectID, 10),
                        damFilename: hit[`damFilename_${language}`],
                        metaQualityRating: hit[`metaQualityRating_${language}`],
                        categories: hit[`categories_${language}`],
                        keywords: hit[`keywords_${language}`],
                        altText: hit[`altText_${language}`],
                        description: hit[`description_${language}`],
                        place: hit[`places_${language}`],
                        isFavorite: (hit.favoritedBy || []).indexOf(parseInt(user.id, 10)) > -1,
                        width: hit['width'] || 1920,
                        height: hit['height'] || 1080,
                        focalPoint: hit['focalPoint'] || { x: 0.5, y: 0.5 },
                        poster: hit['poster'] || null
                    }));

                    this.setFiles(files, meta);

                })
                .catch(error => {
                    console.error(error);
                })
                .then(() => {
                    dispatch(fileBrowserSetIsFetching(false));
                });

            return;

        }

        // NOT SEARCH - USE LOCAL API
        const params = { orderBy, sortOrder };

        if (selectedCategoriesToFilterBy.length) {
            params['categories'] = selectedCategoriesToFilterBy.join(',');
        }

        if (selectedUploadersToFilterBy.length) {
            params['uploaders'] = selectedUploadersToFilterBy.join(',');
        }

        if (selectedFiltersToFilterBy.length) {
            params['filters'] = selectedFiltersToFilterBy.map(filter => filter.id).join(',');
        }

        if (selectedFlagsToFilterBy.length) {
            params['flags'] = selectedFlagsToFilterBy.join(',');
        }

        if (selectedKindsToFilterBy.length === 1) {
            params['kind'] = selectedKindsToFilterBy[0];
        }

        params['page'] = this.currentPage || 1;

        this.fetchFilesCancelTokenSource = axios.CancelToken.source();

        api.getFiles(params, {
            cancelToken: this.fetchFilesCancelTokenSource.token
        })
            .then(({ data }) => data)
            .then(({ data: files, meta }) => {

                const { pagination = {} } = meta || {};

                this.pagination = pagination;
                this.currentPage = pagination.current_page || 1;

                this.setFiles(files, meta);

            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                }
                console.error(error);
            })
            .then(() => {
                dispatch(fileBrowserSetIsFetching(false));
            });

    }

    maybeLoadMore() {

        if (!this.pagination || !!this.props.isFetching || !!this.loadMoreHandler) {
            return;
        }

        this.loadMoreHandler = requestAnimationFrame(() => {

            this.loadMoreHandler = null;

            const { current_page: pagesLoaded, total_pages: totalPages } = this.pagination;

            if (this.currentPage >= totalPages || this.currentPage !== pagesLoaded) {
                return;
            }

            const { current: files } = this.filesRef;
            const { current: scroller } = this.scrollRef;
            const { current: thumbs } = this.thumbsRef;

            if (!files || !scroller || !thumbs) {
                return;
            }

            const { height } = files.getBoundingClientRect();

            const scrollTop = scroller.scrollTop;
            const maxScrollTop = scroller.scrollHeight - (height - 1);

            if (!maxScrollTop) {
                return;
            }

            const thumbsChildren = Array.from(thumbs.childNodes);

            let thumbChildRect;
            let thumbChildScrollOffset;
            let numRenderedThumbChildren = 0;

            thumbsChildren.forEach((thumbsChild, index) => {
                thumbChildRect = thumbsChild.getBoundingClientRect();
                if (!thumbChildRect.height) {
                    return;
                }

                numRenderedThumbChildren++;
                thumbChildScrollOffset = (thumbChildRect.top - thumbs.getBoundingClientRect().top) - scrollTop;

                if (
                    // Thumb child is below the viewport
                    thumbChildScrollOffset > height ||
                    // Thumb child is above the viewport
                    thumbChildScrollOffset < thumbChildRect.height * -1) {
                    thumbsChild.style.visibility = 'hidden';
                } else {
                    thumbsChild.style.visibility = '';
                }
            });

            const remainingScroll = maxScrollTop - scrollTop;

            if (numRenderedThumbChildren < this.currentPage || remainingScroll >= (height * 2)) {
                return;
            }

            console.log('------- new page -------', this.currentPage);

            this.currentPage = pagesLoaded + 1;
            this.fetchFiles();

        });

    }

    onFilesScroll() {
        this.maybeLoadMore();
    }

    onThumbsResize() {
        this.maybeLoadMore();
    }

    componentDidMount() {

        const { current: thumbs } = this.thumbsRef;

        this.resizeObserver = new ResizeObserver(entries => {
            this.maybeLoadMore();
        });

        this.resizeObserver.observe(thumbs);

        this.fetchCategories();
        this.fetchUploaders();
        this.fetchFiles();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const { uploadQueue, selectedIds, files, categories, uploaders, siteId } = this.props;

        // Check if we need to update files
        let doUpdateFiles = false;
        let doUpdateCategories = false;

        if (siteId !== prevProps.siteId) {
            // The site changed. Update everything
            doUpdateFiles = true;
            doUpdateCategories = true;
        } else if ((!uploadQueue || !uploadQueue.length) && (prevProps.uploadQueue && prevProps.uploadQueue.length)) {
            // Uploads were completed (queue was just cleared)
            doUpdateFiles = true;
        } else if (files && prevProps.files && selectedIds && prevProps.selectedIds && selectedIds.length === prevProps.selectedIds.length && files.length < prevProps.files.length) {
            // Files were probably deleted...
            doUpdateFiles = true;
        } else if (prevProps.categories === null && !!categories) {
            // We had no categories, but now we do.
            doUpdateFiles = true;
        } else if (prevProps.uploaders === null && !!uploaders) {
            // We had no uploaders, but now we do.
            doUpdateFiles = true;
        } else {
            // Check other props
            const updateFilesIfChanged = ['orderBy', 'sortOrder', 'searchQuery', 'selectedCategories', 'selectedUploaders', 'selectedFilters', 'selectedFlags', 'selectedKinds'];
            let key;
            for (let i = 0; i < updateFilesIfChanged.length; ++i) {
                key = updateFilesIfChanged[i];
                if (!isEqual(this.props[key], prevProps[key])) {
                    doUpdateFiles = true;
                    break;
                }
            }
        }

        if (doUpdateCategories) {
            this.fetchCategories();
        }

        if (doUpdateFiles) {
            this.currentPage = 1;
            this.fetchFiles();
        } else if (!prevProps.files && files) {
            this.maybeLoadMore();
        }
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            delete this.resizeObserver;
        }
        if (this.fetchFilesCancelTokenSource) {
            this.fetchFilesCancelTokenSource.cancel();
            delete this.fetchFilesCancelTokenSource;
        }
        if (this.loadMoreHandler) {
            cancelAnimationFrame(this.loadMoreHandler);
            this.loadMoreHandler = null;
        }
    }

    render() {

        const { selectedIds, files } = this.props;
        const selectedFiles = selectedIds && selectedIds.length && files && files.length ? selectedIds.map(id => find(files || [], { id })).filter(file => !!file) : [];

        const pages = this.pagination ? chunk(files || [], this.pagination.per_page) : null;

        return (
            <>
                <div className={styles.root}>
                    {/* Interface */}
                    <FileBrowserUi/>
                    {/* File View */}
                    <div style={{
                        position: 'relative',
                        height: 'auto',
                        maxHeight: '100%',
                        flex: '1 1 auto'
                    }}>
                        <UploadDropzone>
                            <div ref={this.filesRef} className={styles.files} onScroll={this.onFilesScroll}>
                                {/* Thumbs/tiles */}
                                <div ref={this.scrollRef}>
                                    <div ref={this.thumbsRef} className={styles.thumbs}>
                                        {(() => {
                                            if (pages === null) {
                                                return null;
                                            }
                                            if (!pages.length) {
                                                return (
                                                    <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                                                        <ErrorFrown text={i18n('No files found')}/>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <>
                                                    {pages.map((filesPerPage, page) => (
                                                        <FileBrowserTiles
                                                            key={`tiles-${page}`}
                                                            files={filesPerPage}
                                                            selectedFiles={selectedFiles}
                                                            selectedIds={selectedIds}
                                                            toggleFile={this.toggleSelectFile}
                                                        />
                                                    ))}
                                                </>
                                            );
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </UploadDropzone>
                    </div>
                    {/* Actions */}
                    {selectedFiles.length ? (
                        <div className={styles.actions}>
                            <FileBrowserActions
                                selectedFiles={selectedFiles}
                                toggleSelect={this.toggleSelectFile}
                            />
                        </div>
                    ) : null}
                </div>
                <FileBrowserContextMenu/>
            </>
        );
    }
}

export default withRouter(connect(state => ({
    uploadQueue: state.uploadQueue,
    siteId: state.selectedSiteId,
    user: state.user,
    allowedKinds: state.allowedKinds,
    ...state.fileBrowser
}))(FileBrowser));
