import React from 'react';
import { ReactComponent as SpinnerSvg } from '../img/spinner.svg';

const Loader = ({ fill = 'white', style = {} }) => (
    <div style={{
        width: 40,
        height: 40,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        ...style
    }}>
        <SpinnerSvg fill={fill} style={{
            display: 'block',
            width: '100%',
            height: '100%'
        }}/>
    </div>
);

export default Loader;
