import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResizeObserver from 'resize-observer-polyfill';

import round from '../utils/round';
import { computeRowLayout } from '../utils/galleryRowLayout';

import FileBrowserTile from "./FileBrowserTile";
import styles from "../styles/filebrowser-tiles.module.scss";

const mapFiles = files => (files || []).map(file => ({
    ...file,
    src: file.url
}));

const findIdealNodeSearch = ({ targetRowHeight, containerWidth }) => {
    const rowAR = containerWidth / targetRowHeight;
    return round(rowAR / 1.5) + 8;
};

class FileBrowserTiles extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            containerWidth: null
        };
    }

    componentDidMount() {
        this.resizeObserver = new ResizeObserver(entries => {
            const { containerWidth } = this.state;
            const width = Math.floor(entries[0].contentRect.width);
            if (width === containerWidth) {
                return;
            }
            this.setState({ containerWidth: width });
        });
        this.resizeObserver.observe(this.ref.current);
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            delete this.resizeObserver;
        }
    }

    render() {

        return (
            <div ref={this.ref} className={styles.root}>
                <div>
                    {(() => {

                        const { containerWidth } = this.state;

                        if (containerWidth === null) {
                            return null;
                        }

                        const { files, selectedIds, selectedFiles, toggleFile, previewSize } = this.props;

                        const margin = 10;

                        const minRowHeight = 100;
                        const maxRowHeight = 600;
                        const targetRowHeight = ((maxRowHeight - minRowHeight) * (previewSize / 100)) + minRowHeight;

                        let limitNodeSearch = 1;
                        if (containerWidth >= 450) {
                            limitNodeSearch = findIdealNodeSearch({ containerWidth, targetRowHeight });
                        }

                        const tiles = computeRowLayout({ containerWidth: containerWidth - 1, limitNodeSearch, targetRowHeight, margin, photos: mapFiles(files) });

                        return (
                            <>
                                {tiles.map(tile => (
                                    <FileBrowserTile
                                        key={`${tile.uid}-${Math.ceil(tile.width / 100) * 100}`}
                                        selectedFiles={selectedFiles}
                                        toggleFile={toggleFile}
                                        isSelected={(selectedIds || []).indexOf(tile.id) > -1}
                                        margin={margin}
                                        previewSize={previewSize}
                                        {...tile}
                                    />
                                ))}
                            </>
                        );
                    })()}
                </div>
            </div>
        );
    }

}

export default connect(state => ({
    previewSize: state.fileBrowser.tilePreviewSize
}))(FileBrowserTiles);
