import React, { useState } from 'react';
import { connect } from 'react-redux';

import Dialog from '../Dialog';
import Button from '../Button';
import OptionInputGroup from '../inputs/OptionInputGroup';
import OptionInput from "../inputs/OptionInput";

import { deleteFile, replaceFile } from '../../api';
import { resolveUploadConflicts } from '../../store';

import styles from '../../styles/dialog-basic.module.scss';
import i18n from "../../i18n";

const ConflictingUploadsDialog = ({ conflictedUploads, onCancel, dispatch }) => {

    const [action, setAction] = useState(null);
    const [applyToAll, setApplyToAll] = useState(false);
    const [isResolving, setIsResolving] = useState(false);

    const totalConflictingUploads = conflictedUploads.length;
    const enableSubmit = !!action;

    const conflictingUpload = conflictedUploads[0];
    const { filename } = conflictingUpload;

    return (
        <Dialog>
            <div className={styles.dialog}>
                {/* Text/content */}
                <div style={{
                    maxWidth: 680
                }}>
                    <h2 className={styles.title}>{i18n('A file with the name {filename} already exists.', { filename })}</h2>
                    <div className={styles.text}>
                        <OptionInputGroup
                            name="conflicting-uploads-action"
                            value={action ? [action] : null}
                            options={[{
                                value: 'keep-both',
                                label: 'Keep both'
                            }, {
                                value: 'replace-original',
                                label: i18n('Replace original')
                            }, {
                                value: 'skip-duplicate',
                                label: i18n('Discard duplicate')
                            }]}
                            onChange={values => {
                                setAction(values[0] || null);
                            }}
                        />
                    </div>
                </div>
                {/* Actions */}
                <div className={styles.actions}>
                    <div className={styles.confirm}>
                        <Button label={i18n('Cancel')} onClick={onCancel}/>
                        <Button submit={true} label={i18n('OK')} onClick={() => {
                            // Resolve all, or just the one?
                            const uploadsToResolve = applyToAll ? conflictedUploads : [conflictingUpload];
                            if (!action || action === 'keep-both') {
                                dispatch(resolveUploadConflicts({
                                    uploads: uploadsToResolve
                                }));
                            } else {
                                setIsResolving(true);
                                let totalToResolve = uploadsToResolve.length;
                                let numResolved = 0;
                                uploadsToResolve.forEach(upload => {
                                    if (action === 'replace-original') {
                                        replaceFile({
                                            sourceAssetId: upload.assetId, // This is the one we're gonna use
                                            assetId: upload.conflictingAssetId // This is the one to be replaced
                                        })
                                            .catch(error => {
                                                console.error(error); // TODO handle errors 4 real
                                            })
                                            .then(res => {
                                                const { status, data } = res;
                                                if (status === 200 && data && data.assetId) {
                                                    upload = {
                                                        ...upload,
                                                        assetId: parseInt(data.assetId, 10)
                                                    };
                                                }
                                            })
                                            .finally(() => {
                                                numResolved += 1;
                                                if (numResolved >= totalToResolve) {
                                                    setIsResolving(false);
                                                    dispatch(resolveUploadConflicts({
                                                        uploads: [upload]
                                                    }));
                                                }
                                            });
                                    } else if (action === 'skip-duplicate') {
                                        deleteFile(upload.assetId)
                                            .catch(error => {
                                                console.error(error);
                                            })
                                            .finally(() => {
                                                numResolved += 1;
                                                if (numResolved >= totalToResolve) {
                                                    setIsResolving(false);
                                                    dispatch(resolveUploadConflicts({
                                                        uploads: [{
                                                            ...upload,
                                                            assetId: parseInt(upload.conflictingAssetId, 10)
                                                        }]
                                                    }));
                                                }
                                            });
                                    } else {
                                        console.error(`Unknown upload resolve action ${action}`);
                                    }
                                });
                            }
                        }} disabled={!enableSubmit} showLoader={isResolving}/>
                    </div>
                    <div className={styles.dangerous}>
                        {totalConflictingUploads > 1 ? (
                            <div>
                                <OptionInput type="checkbox"
                                             label={i18n('Do this with the remaining {count} conflicts?', { count: totalConflictingUploads - 1 })}
                                             onChange={() => setApplyToAll(!applyToAll)}
                                             checked={applyToAll}/>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default connect()(ConflictingUploadsDialog);
