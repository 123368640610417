import React, { useState } from 'react';
import Dialog from "../Dialog";

import styles from "../../styles/dialog-basic.module.scss";

import Button from "../Button";
import i18n from "../../i18n";
import OptionInputGroup from "../inputs/OptionInputGroup";
import OptionInput from "../inputs/OptionInput";
import PopoutMenu from "../PopoutMenu";
import ButtonGroup from "../ButtonGroup";


const SuggestedTagsDialog = ({ title, text, options, onConfirm, onCancel, confirmLabel = i18n('Select'), cancelLabel = i18n('Cancel') }) => {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const canSubmit = !!selectedOptions.length;

    return (
        <Dialog>
            <div className={styles.dialog}>
                {/* Text/content */}
                {title || text ? (
                    <div>
                        {title ? (<h2 className={styles.title}>{title}</h2>) : null}
                        {text ? (
                            <div className={styles.text}>
                                <p dangerouslySetInnerHTML={{ __html: text }}/>
                            </div>
                        ) : null}
                    </div>
                ) : null}
                {/* Options */}
                <div style={{
                    paddingTop: 30
                }}>
                    <OptionInputGroup
                        options={options || []}
                        type="checkbox"
                        layout="columns"
                        value={selectedOptions}
                        onChange={values => setSelectedOptions(values)}
                    />
                </div>
                {/* Actions */}
                {onConfirm || onCancel ? (
                    <div className={styles.actions} style={{ paddingTop: 30 }}>
                        <div className={styles.confirm}>
                            {onCancel ? (
                                <Button label={cancelLabel} onClick={onCancel}/>
                            ) : null}
                            {onConfirm ? (
                                <ButtonGroup submit={true} style={{ position: 'relative' }} disabled={!canSubmit}>
                                    <Button label={confirmLabel} onClick={() => onConfirm(({
                                        selectedOptions,
                                        discardUnusedSuggestions: false
                                    }))}/>
                                    <PopoutMenu>
                                        <button onClick={() => {
                                            onConfirm({
                                                selectedOptions,
                                                discardUnusedSuggestions: true
                                            });
                                        }}>{i18n('Add and Discard Unused')}</button>
                                    </PopoutMenu>
                                </ButtonGroup>

                            ) : null}
                        </div>
                        {options && options.length > 1 ? (
                            <div className={styles.dangerous}>
                                {(() => {
                                    const allChecked = selectedOptions.length >= options.length;
                                    return (
                                        <OptionInput label={i18n('Select all')} type="checkbox" checked={allChecked} onChange={() => {
                                            if (allChecked) {
                                                setSelectedOptions([]);
                                            } else {
                                                setSelectedOptions(options.map(option => option.value));
                                            }
                                        }}/>
                                    );
                                })()}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </Dialog>
    );
};

export default SuggestedTagsDialog;
