import React from 'react';
import { connect } from 'react-redux';
import { ContextMenu, connectMenu, hideMenu } from "react-contextmenu";
import { Link } from "react-router-dom";

import ContextMenuPortal from "./ContextMenuPortal";

import i18n from "../i18n";
import { ActionButtons } from "./FileBrowserActions";

import { CONTEXT_FIELD } from "../helpers";

export const CONTEXT_MENU_ID = 'filebrowser-context-menu';

const FileBrowserContextMenu = ({ context, trigger, dispatch }) => {

    if (context === CONTEXT_FIELD) {
        return null;
    }

    const { file } = trigger || {};

    return (
        <ContextMenuPortal>
            <ContextMenu id={CONTEXT_MENU_ID}>
                {(() => {
                    if (!file || !file.id) {
                        // Hack to get around the require `children` prop for <ContextMenu/> component
                        return (<></>);
                    }
                    const files = [file]; // TODO can hook into `selectedIds` here, if need be
                    return (
                        <div onClick={() => {
                            hideMenu({
                                id: CONTEXT_MENU_ID
                            });
                        }}>
                            {files.length > 1 ? (
                                <Link to={`/edit/${files.map(file => file.id).join(',')}`}>{i18n('Edit {count} Files', { count: files.length })}</Link>
                            ) : (
                                <Link to={`/edit/${file.id}`}>{i18n('Edit File')}</Link>
                            )}
                            <ActionButtons
                                displayDangerousActions={false}
                                selectedFiles={files}
                                dispatch={dispatch}
                            />
                        </div>
                    );
                })()}
            </ContextMenu>
        </ContextMenuPortal>
    );
};

export default connectMenu(CONTEXT_MENU_ID)(connect(state => ({
    context: state.context
}))(FileBrowserContextMenu));
