import React from 'react';
import { components } from 'react-select';

import { ReactComponent as RemoveSvg } from '../img/remove.svg';

const RemoveTagBtn = props => (
    <components.MultiValueRemove {...props}>
        <RemoveSvg style={{
            width: 10,
            height: 10
        }}/>
    </components.MultiValueRemove>
);

export default RemoveTagBtn;
