const BUNNY_PULL_ZONE = 'https://escapedam-eu-west-1.b-cdn.net/';

const fillInterval = 400;

export const getUrl = (path, params = {}) => {
    let pullUrl = new URL(`${BUNNY_PULL_ZONE}${path}`);
    let bunnyParams = {};
    if (params) {
        if (params.width) {
            bunnyParams.width = Math.ceil(params.width/fillInterval)*fillInterval;
        } else if (params.height) {
            bunnyParams.height = Math.ceil(params.height/fillInterval)*fillInterval;
        }
        if (params.aspect_ratio) {
            bunnyParams.aspect_ratio = params.aspect_ratio;
        }
    }
    Object.keys(bunnyParams).forEach(param => {
        pullUrl.searchParams.set(param, bunnyParams[param]);
    });
    return pullUrl.toString();
};
