import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';

import get from 'lodash/get';

import BasicDialog from "./dialogs/BasicDialog";
import ConflictingUploadsDialog from "./dialogs/ConflictingUploadsDialog";

import {
    clearUploadQueue,
    onFileUploadStart,
    onFileUploadSuccess,
    onFileUploadError,
    fileBrowserSelectIds,
    fileBrowserClearSelectedIds,
    fileBrowserSetOrderBy,
    fileBrowserSetSortOrder, fileBrowserSetSearchQuery, fileBrowserSetSelectedCategories
} from '../store';

import * as api from '../api';

import i18n from "../i18n";

import styles from '../styles/upload-queue.module.scss';

export const uploadFile = file => dispatch => {

    dispatch(onFileUploadStart({ file }));

    api
        .uploadFile(file)
        .then(res => {
            const { status, data } = res;
            if (status !== 200 || data.error) {
                dispatch(onFileUploadError({
                    file,
                    error: data.error || i18n('An unknown error occurred')
                }));
            } else {
                dispatch(onFileUploadSuccess({ file, data }));
            }
        })
        .catch(error => {
            dispatch(onFileUploadError({
                file,
                error: get(error, 'response.data.error', error)
            }));
            console.error(error);
        });
};

const UploadQueue = ({ queue, dispatch, history }) => {

    const [alert, setAlert] = useState(null);
    const [conflictedUploads, setConflictedUploads] = useState(null);

    // Deal with queue changes
    useEffect(() => {

        // No queue or already a file in progress, GTFO
        if (!queue || find(queue, { status: 'progress' })) {
            return;
        }

        // Get the first pending file
        const upload = find(queue, { status: 'pending' });
        const file = upload ? upload.file : null;

        if (file) {

            // Upload it
            dispatch(uploadFile(file));

        } else {

            // I can haz conflicted files?
            const conflictedUploads = queue.filter(({ conflict, conflictingAssetId }) => !!(conflict && conflictingAssetId));
            if (conflictedUploads.length) {
                setConflictedUploads(conflictedUploads);
                return;
            }

            // Nope, queue complete – but if there were failed uploads, create an alert dialog to make the user aware
            const failedUploads = (queue || []).filter(({ status }) => status === 'failed');
            const successUploads = (queue || []).filter(({ status }) => status === 'complete');

            if (failedUploads.length) {

                const text = [];

                if (successUploads.length) {
                    text.push(i18n('{count} files successfully uploaded', { count: successUploads.length }));
                }
                if (failedUploads.length > 1) {
                    text.push(i18n('{count} files failed to upload.', { count: failedUploads.length }));
                } else {
                    text.push(i18n('{filename} failed to upload: <em>{error}</em>', {
                        filename: failedUploads[0].file.name,
                        error: failedUploads[0].error || i18n('Unknown error')
                    }));
                }

                const title = successUploads.length ? i18n('Upload complete') : i18n('Upload failed');

                setAlert({
                    title,
                    text: text.join('<br/>'),
                    onConfirm: () => {
                        dispatch(clearUploadQueue());
                        setAlert(null);
                    }
                });

            } else {
                // Nope, all good – just clear the queue
                dispatch(clearUploadQueue());
            }

            if (!successUploads.length) {
                // No successful uploads – clear selected files from the browser
                dispatch(fileBrowserClearSelectedIds());
                return;
            }

            const assetIds = successUploads.map(upload => parseInt(upload.assetId, 10));

            // Select the fresh images in the browser
            dispatch(fileBrowserSelectIds({ assetIds }));

            // Set the sort order to date uploaded desc, in order to have the new images at the top
            dispatch(fileBrowserSetSearchQuery(null));
            dispatch(fileBrowserSetSelectedCategories(null));
            dispatch(fileBrowserSetOrderBy('dateUploaded'));
            dispatch(fileBrowserSetSortOrder('DESC'));

            // Open edit view
            history.push(`/edit/${assetIds.join(',')}`);

        }

        return () => {
            setAlert(null);
            setConflictedUploads(null);
        };

    }, [queue]);

    return (
        <>
            {(() => {
                // Display dialogs for conflicting uploads resolving and/or generic alert
                if (conflictedUploads && conflictedUploads.length) {
                    return (
                        <ConflictingUploadsDialog
                            conflictedUploads={conflictedUploads}
                            onCancel={() => {
                                setConflictedUploads(null);
                                dispatch(clearUploadQueue());
                            }}
                        />
                    );
                } else if (alert) {
                    return (
                        <BasicDialog {...alert}/>
                    );
                }
                return null;
            })()}
            {(() => {
                if (!queue || !queue.length) {
                    return null;
                }
                const queueProgress = queue.filter(upload => upload.status !== 'pending').length / queue.length;
                const total = queue.length;
                let index = Math.floor(queue.length * queueProgress) - 1;
                if (index < 0) {
                    index = 0;
                }
                const file = queue[index] || null;
                return (
                    <div className={styles.root}>
                        <div className={styles.wrap}>
                            <p className={styles.status}>
                                <span>
                                    {i18n('Uploading {index} of {total}', {
                                        index: index + 1,
                                        total
                                    })}
                                </span>
                                {file ? (
                                    <span>{file.file.name}</span>
                                ) : null}
                            </p>
                            <div className={styles.bar}>
                                <div className={styles.stripes}/>
                                <div className={styles.progress} style={{
                                    transform: `scale(${queueProgress}, 1)`
                                }}/>
                            </div>
                        </div>
                    </div>
                );
            })()}
        </>
    );

};

export default withRouter(connect(state => ({
    queue: state.uploadQueue
}))(UploadQueue));
