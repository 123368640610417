import React from 'react';
import { ReactComponent as FrownSvg } from "../img/frown.svg";

const ErrorFrown = ({ text, opacity = 0.55 }) => (
    <div style={{
        display: 'flex',
        width: '100%',
        minHeight: '100%'
    }}>
        <div style={{
            margin: 'auto',
            fontSize: 16,
            fontWeight: 600
        }}>
            <FrownSvg style={{
                width: 60,
                fill: 'currentColor',
                opacity: opacity - 0.25,
                margin: '0 auto 10px'
            }}/>
            <p style={{
                opacity
            }}>{text}</p>
        </div>
    </div>
);

export default ErrorFrown;
