import algoliasearch from 'algoliasearch/lite';
import store from './store';
import find from 'lodash/find';

const FILES_INDEX_NAME = 'files';
const KEYWORDS_INDEX_NAME = 'keywords';
const PLACES_INDEX_NAME = 'places';

const getClient = () => algoliasearch('JVRMO2CTWE', 'fc864904d5ed3c32a714246d308b9df1');

export const getLanguage = () => {
    const { sites, selectedSiteId } = store.getState();
    const selectedSite = find(sites, { id: selectedSiteId }, sites[0] || null);
    let language = selectedSite ? selectedSite.language : 'en';
    if (language === 'nb' || language === 'nn') {
        language = 'no';
    }
    return language;
};

export const getTagsForSearch = query => {

    const client = getClient();
    const language = getLanguage();
    const hitsPerPage = 100;

    const queries = [{
        indexName: PLACES_INDEX_NAME,
        query,
        params: {
            restrictSearchableAttributes: `title_${language}`,
            attributesToRetrieve: `title_${language}`,
            hitsPerPage
        }
    }, {
        indexName: KEYWORDS_INDEX_NAME,
        query,
        params: {
            restrictSearchableAttributes: `title_${language}`,
            attributesToRetrieve: `title_${language}`,
            hitsPerPage
        }
    }];

    return client.multipleQueries(queries);

};

export const getKeywords = query => {

    const client = getClient();
    const language = getLanguage();
    const hitsPerPage = 50;

    const index = client.initIndex(KEYWORDS_INDEX_NAME);

    return index.search(query, {
        restrictSearchableAttributes: `title_${language}`,
        attributesToRetrieve: `title_${language}`,
        hitsPerPage
    });

};

export const fetchFiles = (query, params = {}) => {

    const client = getClient();
    const language = getLanguage();
    const index = client.initIndex(FILES_INDEX_NAME);
    const hitsPerPage = 50;
    const { page = 0, filters = '' } = params;

    return index.search(query, {
        restrictSearchableAttributes: `title_${language},places_${language},keywords_${language},damFilename_${language},altText_${language},description_${language}`,
        hitsPerPage,
        page,
        filters
        //numericFilters: ['width > 0', 'height >0']
    });

};
