import React, { useRef } from 'react';
import classNames from 'classnames';

import OptionInput from "./OptionInput";

import styles from '../../styles/input/option-group.module.scss';

const OptionInputGroup = ({ name, label, type, layout = 'vertical', options, value: propValue, onChange: propOnChange, style = {}, ...props }) => {

    const ref = useRef(null);

    const onChange = () => {
        const values = Array.from(ref.current.querySelectorAll('input')).map(input => input.checked ? input.value : null).filter(value => !!value);
        if (propOnChange) {
            propOnChange(values);
        }
    };

    return (
        <fieldset className={classNames(styles.root, `is-${layout}`)} style={style}>
            {label ? (
                <legend>{label}</legend>
            ) : null}
            <div ref={ref} onChange={onChange} className={styles.options} style={{
                style
            }} {...props}>
                {(options || []).map((option, index) => {
                    const defaultChecked = propValue && (propValue.toString() === option.value.toString() || (propValue.map(value => value.toString())).indexOf(option.value.toString()) > -1);
                    return (
                        <OptionInput
                            key={`${name}-${index}`}
                            type={type}
                            name={name}
                            value={option.value}
                            label={option.label}
                            defaultChecked={defaultChecked}
                        />
                    );
                })}
            </div>
        </fieldset>
    );
};

export default OptionInputGroup;
