import React from 'react';
import { createPortal } from 'react-dom';

const ContextMenuPortal = ({ children }) => children ? createPortal(
    <>
        {children}
    </>
, document.body) : null;

export default ContextMenuPortal;
