import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';

import OptionInputGroup from "./inputs/OptionInputGroup";
import TextInput from "./inputs/TextInput";
import i18n from "../i18n";
import TagsInput from "./inputs/TagsInput";
import MultiLangTextInput from "./inputs/MultiLangTextInput";

import * as api from "../api";
import * as algolia from '../algolia';
import * as googleMaps from "../googleMaps";

import { setCategories } from "../store";

const categoriesToTagOptions = (categories, data = {}) => categories.map(category => ({
    value: parseInt(category.id, 10) || category.title,
    label: category.title,
    ...category,
    ...data
}));

const tagOptionsToCategories = options => options.map(option => ({
    id: parseInt(option.value, 10) || option.value,
    title: option.label,
    ...option
}));

const FileEditorContent = ({ data, categories, sites, selectedSiteId, isGoogleMapsApiLoaded, setState, dispatch }) => {

    if (!data || !Object.values(data).length) {
        console.error('No data');
        return;
    }

    const [isSingleColumn, setSingleColumn] = useState(false);

    const ref = useRef(null);

    // Get the current site
    const currentSite = find(sites, { id: selectedSiteId }, sites[0]);
    const currentLanguage = currentSite.language;
    const asset = data[currentLanguage] || null;

    if (!asset) {
        console.error('No asset');
        return;
    }

    const isSingleView = !!asset.hasOwnProperty('damFilename');

    const selectedCategories = categoriesToTagOptions(asset.categories || [], {
        type: 'category',
        group: 'categories'
    });

    const selectedKeywords = categoriesToTagOptions(asset.keywords || [], {
        type: 'tag',
        group: 'keywords'
    });

    const selectedPlaces = categoriesToTagOptions(asset.place || [], {
        type: 'tag',
        group: 'place'
    });

    const autoAddedPlaces = categoriesToTagOptions(asset.autoAddedPlaces || []);

    // Build an array of suggested keywords
    const selectedKeywordTitles = selectedKeywords.map(keyword => keyword.label.toLowerCase());
    const suggestedKeywords = (asset.suggestedKeywords || []).filter(keyword => selectedKeywordTitles.indexOf(keyword.toLowerCase()) === -1);

    const onResize = () => {
        if (!ref.current) {
            return;
        }
        const { width } = ref.current.getBoundingClientRect();
        setSingleColumn(!!(width <= 680));
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        // Refresh categories
        api.getCategories()
            .then(({ data }) => data)
            .then(({ data: categories }) => dispatch(setCategories(categories)))
            .catch(error => {
                console.error(error);
            });
    }, [selectedSiteId]);

    // Set focus on mount
    useEffect(() => {
        const { current: el } = ref;
        try {
            el.focus();
        } catch (error) {
            console.error(error);
        }
    }, []);

    const onKeyUp = e => {
        e.stopPropagation();
    };

    return (
        <div ref={ref}>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: 'calc(100% + 36px)',
                marginLeft: -18
            }} onKeyUp={onKeyUp} tabIndex={-1}>

                <div style={{
                    width: isSingleColumn ? '100%' : '50%',
                    padding: '0 18px',
                    flex: '1 1 auto'
                }}>

                    {/* Categories */}
                    {categories && categories.length ? (
                        <OptionInputGroup
                            type="checkbox"
                            label={i18n('Categories')}
                            value={selectedCategories.map(category => category.value)}
                            options={categoriesToTagOptions(categories)}
                            layout={'horizontal'}
                            style={{ marginBottom: 25 }}
                            onChange={values => {
                                setState({ categories: (values || []).map(value => ({ id: parseInt(value, 10) })) });
                            }}/>
                    ) : null}

                    {isSingleView ? (
                        <MultiLangTextInput
                            label={i18n('Filename')}
                            value={mapValues(data, 'damFilename')}
                            postfix={`.${asset.extension}`}
                            onChange={value => setState(mapValues(value, damFilename => ({ damFilename })))}
                            translateable={false}
                            sites={sites}
                            style={{ marginBottom: 24 }}
                        />
                    ) : null}

                    <MultiLangTextInput
                        label={i18n('Caption')}
                        value={mapValues(data, 'description')}
                        multiline={true}
                        sites={sites}
                        onChange={value => setState(mapValues(value, description => ({ description })))}
                        style={{ marginBottom: 24 }}
                    />

                    <MultiLangTextInput
                        label={i18n('Alternative text')}
                        value={mapValues(data, 'altText')}
                        multiline={true}
                        sites={sites}
                        onChange={value => setState(mapValues(value, altText => ({ altText })))}
                        instructions={i18n('The alternative text is essential for accessibility, and should describe the <em>appearance</em> and <em>function</em> of the image.')}
                        style={{ marginBottom: 24 }}
                    />

                </div>
                <div style={{
                    width: isSingleColumn ? '100%' : '50%',
                    padding: '0 18px',
                    flex: '1 1 auto'
                }}>

                    {/* Place */}
                    <div style={{ marginBottom: 24 }}>
                        <TagsInput
                            key={`place-input-${currentLanguage}`}
                            label={i18n('Place')}
                            value={selectedPlaces}
                            multiple={true}
                            createable={false}
                            editableTags={true}
                            defaultOptions={false}
                            displayDropdownArrow={false}
                            language={currentLanguage}
                            placeholder={i18n('Add place...')}
                            disabled={!isGoogleMapsApiLoaded}
                            loadOptions={search =>
                                googleMaps
                                    .getAutocompletePlaces(search)
                                    .then(results => results.map(result => ({
                                        value: result.place_id,
                                        label: result.description,
                                        placeId: result.place_id
                                    })).filter(place => !find(selectedPlaces, { placeId: place.placeId })))
                                    .catch(error => {
                                        console.error(error);
                                    })
                            }
                            onChange={places => setState({
                                [currentLanguage]: {
                                    place: tagOptionsToCategories(places || [])
                                }
                            })}
                            instructions={i18n('New places will be auto-translated from <em>{language}</em>.\nDouble-click existing places to view and edit translations.', { language: currentSite.name })}
                        />
                        {autoAddedPlaces.length ? (
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                paddingTop: 5
                            }}>
                                <p style={{ fontSize: 12, lineHeight: 1.2, marginRight: 5, opacity: 0.65 }}>Auto added: </p>
                                <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {autoAddedPlaces.map((placeTag, index) => (
                                        <span key={placeTag.uid} style={{
                                            display: 'block',
                                            flex: '0 0 auto',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            padding: '3px 5px',
                                            borderRadius: 8,
                                            lineHeight: 1,
                                            backgroundColor: '#465566',
                                            color: 'rgba(255, 255, 255, 0.65)',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            marginRight: index < autoAddedPlaces.length - 1 ? 5 : 0,
                                            userSelect: 'none'
                                        }}>{placeTag.title}</span>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                    </div>

                    {/* Keywords */}
                    <TagsInput
                        label={i18n('Keywords')}
                        key={`keywords-input-${currentLanguage}`}
                        value={selectedKeywords}
                        multiple={true}
                        defaultOptions={false}
                        createable={true}
                        editableTags={true}
                        language={currentLanguage}
                        placeholder={i18n('Add keywords...')}
                        loadOptions={search =>
                            algolia.getKeywords(search)
                                .then(({ hits }) => {
                                    let language = currentLanguage;
                                    if (language === 'nb' || language === 'nn') {
                                        language = 'no';
                                    }
                                    return categoriesToTagOptions(hits.map(hit => ({
                                        id: hit.objectID,
                                        title: hit[`title_${language}`]
                                    })));
                                })
                        }
                        onChange={(keywords, { discardUnusedSuggestions }) => {
                            let state = {
                                keywords: tagOptionsToCategories(keywords || [])
                            };
                            if (discardUnusedSuggestions === true) {
                                state.suggestedKeywords = (asset.suggestedKeywords || []).filter(keyword => find(state.keywords, { title: keyword }));
                            }
                            setState({
                                [currentLanguage]: state
                            });
                        }}
                        suggestionsDialog={suggestedKeywords ? {
                            options: suggestedKeywords.map(suggestion => ({
                                value: suggestion,
                                label: suggestion
                            })),
                            title: i18n('Keyword suggested for this image'),
                            text: i18n('Please select the keywords you want to add.'),
                            confirmLabel: i18n('Add keywords')
                        } : null}
                        instructions={i18n('New keywords will be auto-translated from <em>{language}</em>.\nDouble-click existing keywords to view and edit translations.', { language: currentSite.name })}
                        style={{ marginBottom: 24 }}/>

                    <TextInput label={i18n('Credit')} value={asset.credit} onChange={e => {
                        setState({ credit: e.target.value });
                    }} style={{ marginBottom: 24 }}/>

                    <TextInput label={i18n('Copyright Notice')} value={asset.copyrightNotice}
                               multiline={true}
                               onChange={e => {
                                   setState({ copyrightNotice: e.target.value });
                               }} style={{ marginBottom: 24 }}/>

                    <TextInput label={i18n('Usage Restrictions')} value={asset.usageRestrictions}
                               multiline={true}
                               onChange={e => {
                                   setState({ usageRestrictions: e.target.value });
                               }} style={{ marginBottom: 24 }}/>

                </div>
            </div>
        </div>
    );
};

export default connect(state => ({
    categories: state.categories || [],
    sites: state.sites,
    selectedSiteId: state.selectedSiteId,
    isGoogleMapsApiLoaded: state.isGoogleMapsApiLoaded
}))(FileEditorContent);
