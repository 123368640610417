import React from 'react';
import TinyPopover, { ArrowContainer as TinyPopoverArrowContainer } from 'react-tiny-popover';
import { hexToRgb } from "../helpers";

const Popover = ({ isOpen, setOpen, position = 'top', content, children, style = {} }) => {

    let { backgroundColor = '#1C2127', color = '#ffffff' } = style;
    if (backgroundColor[0] === '#') {
        const backgroundColorRgb = hexToRgb(backgroundColor);
        backgroundColor = `rgba(${backgroundColorRgb.r}, ${backgroundColorRgb.g}, ${backgroundColorRgb.b}, 0.85)`;
    }

    return (
        <TinyPopover
            isOpen={isOpen}
            transitionDuration={0.0000001}
            position={position}
            onClickOutside={() => setOpen(false)}
            padding={-2}
            content={({ position, targetRect, popoverRect }) => (
                <TinyPopoverArrowContainer
                    position={position}
                    targetRect={targetRect}
                    popoverRect={popoverRect}
                    arrowColor={backgroundColor}
                    arrowSize={6}
                    arrowStyle={{
                        boxShadow: '0px 3px 3px 0px rgba(51, 63, 77, 0.75)',
                    }}
                >
                    <div style={{
                        backgroundColor,
                        color,
                        maxWidth: 280,
                        fontSize: 12,
                        lineHeight: 1.2,
                        padding: '8px 12px',
                        boxShadow: '0px 0px 3px 0px rgba(51, 63, 77, 0.75)',
                        borderRadius: 3,
                        ...style
                    }}>
                        {content ? content : null}
                    </div>
                </TinyPopoverArrowContainer>
            )}
        >
            {children}
        </TinyPopover>
    );
};

export default Popover;
