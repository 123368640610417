import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {useDropzone} from 'react-dropzone';
import i18n from "../i18n";

import {addFilesToQueue} from "../store";

import styles from '../styles/upload-dropzone.module.scss';

const UploadDropzone = ({children, dispatch, allowedExtensions }) => {

    const onDrop = useCallback(acceptedFiles => {
        if (!acceptedFiles || !acceptedFiles.length) {
            return;
        }
        dispatch(addFilesToQueue({
            files: Array.from(acceptedFiles)
        }));
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        accept: allowedExtensions.map(extension => `.${extension}`).join(',')
    });

    return (
        <div {...getRootProps()} className={styles.root}>
            {isDragActive ? (
                <div className={styles.msg}>
                    <p>{i18n('Drop files to upload')}</p>
                </div>
            ) : null}
            {children}
            <input {...getInputProps()} />
        </div>
    );
};

export default connect(state => ({
    allowedExtensions: state.allowedExtensions
}))(UploadDropzone);
