import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BasicDialog from "./BasicDialog";
import i18n from "../../i18n";

import styles from '../../styles/dialog-basic.module.scss';

const AccountDialog = ({ user, history }) => {
    return (
        <BasicDialog
            title={i18n('My Account')}
            onCancel={() => history.go(-1)}
            cancelLabel={i18n('Close')}
            onConfirm={() => {
                history.go(-1);
            }}
            confirmLabel={i18n('Update Profile')}
            style={{
                width: '100%',
                maxWidth: 520
            }}
        >
            <div className={styles.text} style={{
                padding: '20px 0'
            }}>
                <p>{i18n('Currently logged in as {email}', { email: user.email })}</p>
                <p><em>TODO: User profile form</em></p>
            </div>
        </BasicDialog>
    );
};

export default withRouter(connect(state => ({
    user: state.user
}))(AccountDialog));
