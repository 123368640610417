import React, { useState } from 'react';
import Slider from 'react-input-slider';

import styles from '../../styles/input/range-input.module.scss';

const RangeInput = ({ label, step, value, onChange }) => {

    const [state, setState] = useState({ x: parseFloat(value) });

    return (
        <div className={styles.root}>
            {label ? (
                <label>{label}</label>
            ) : null}
            <div className={styles.input}>
                <Slider
                    axis="x"
                    x={onChange ? parseFloat(value) : state.x}
                    xstep={step || 1}
                    onChange={({ x }) => {
                        if (onChange) {
                            onChange({ x });
                        } else {
                            setState(state => ({ ...state, x }));
                        }
                    }}
                    styles={{
                        track: {
                            height: 1,
                            backgroundColor: 'rgba(51, 63, 77, 0.5)'
                        },
                        active: {
                            backgroundColor: 'rgba(51, 63, 77, 1)'
                        },
                        thumb: {
                            width: 12,
                            height: 12,
                            backgroundColor: 'rgba(51, 63, 77, 1)',
                            boxShadow: null,
                            cursor: 'pointer'
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default RangeInput;
