//import store from './store';
//import get from 'lodash/get';

export default (key, vars = {}) => {
    let value = key; // TODO
    // const { lang, data } = store.getState();
    // let value = get(data, `i18n.${key}.${lang}`, key);
    Object.keys(vars).forEach(name => {
        const pattern = `{${name}}`;
        value = value.replace(new RegExp(pattern, 'g'), vars[name]);
    });
    return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
};
