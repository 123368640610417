import React from 'react';
import { createPortal } from 'react-dom';

const Dialog = ({ children }) => children ? createPortal(
    <div className="u-scrollable" role="dialog" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }}>
        {children}
    </div>
, document.body) : null;

export default Dialog;
